import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import { EVENT_TITLES } from "../../../utils/constants";
import SidePanelComponent from "../../SidePanelComponents";

const DynamicSlidingPanel = () => {
  const sidePanelReducer = useSelector((state) => state.sidePanelReducer);

  const [openPanel, setOpenPanel] = useState(false);

  useEffect(() => {
    setOpenPanel(sidePanelReducer.isSidePanelOpen);
  }, [sidePanelReducer]);

  const closePanel = () => {
    setOpenPanel(false);
  };

  return (
    <div>
      <SlidingPane
        type={"right"}
        isOpen={openPanel}
        width="30%"
        onRequestClose={closePanel}
        title={EVENT_TITLES[sidePanelReducer?.sidePanelType]}
      >
        <SidePanelComponent
          sidePanelType={sidePanelReducer.sidePanelType}
          selectedData={sidePanelReducer.selectedData}
          closePanel={closePanel}
        />
      </SlidingPane>
    </div>
  );
};

export default DynamicSlidingPanel;
