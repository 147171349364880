const ACTION_TYPE_ENUMS = {
  OPEN_SIDE_PANEL: "OPEN_SIDE_PANEL",
  CLOSE_SIDE_PANEL: 'CLOSE_SIDE_PANEL',
  UPDATE_PRIORITY_TYPES: 'UPDATE_PRIORITY_TYPES',
  UPDATE_EVENT_TYPES: 'UPDATE_EVENT_TYPES',
  UPDATE_GROUP_LIST: 'UPDATE_GROUP_LIST',
  UPDATE_SELECTED_GUESTS: 'UPDATE_SELECTED_GUESTS',
  UPDATE_SELECTED_EVENT: 'UPDATE_SELECTED_EVENT',
  UPDATE_SELECTED_STATUS: 'UPDATE_SELECTED_STATUS',
  UPDATE_USER_INFO: 'UPDATE_USER_INFO',
  REFRESH_DATA: 'REFRESH_DATA',
  SHOW_LOADER: 'SHOW_LOADER',
  HIDE_LOADER: "HIDE_LOADER",
  UPDATE_SELECTED_CONTACT: 'UPDATE_SELECTED_CONTACT'
};

export { ACTION_TYPE_ENUMS };
