import axios from "axios";

const BASE_URL = process.env.REACT_APP_ALT_BASE_SERVER_URL;

const getGuestList = async () => {
  const token = sessionStorage.getItem("userToken");

  try {
    const response = await axios.get(`${BASE_URL}/api/event/person/list`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

const saveGuestDetails = async (guestData) => {
  const token = sessionStorage.getItem("userToken");

  try {
    const response = await axios.post(
      `${BASE_URL}/api/person/set`,
      {
        ...guestData,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

const saveGroupDetails = async (groupDetails) => {
  const token = sessionStorage.getItem("userToken");

  try {
    const response = await axios.post(
      `${BASE_URL}/api/person/set/group`,
      {
        ...groupDetails,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

const getGroupList = async () => {
  const token = sessionStorage.getItem("userToken");

  try {
    const response = await axios.get(`${BASE_URL}/api/person/group/list`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

const assignGroupLeader = async (groupId, personId) => {
  const token = sessionStorage.getItem("userToken");

  try {
    const response = await axios.get(
      `${BASE_URL}/api/person/group/${groupId}/assignLeader/${personId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

const getEventList = async () => {
  const token = sessionStorage.getItem("userToken");

  try {
    const response = await axios.get(`${BASE_URL}/api/event/subEvent/list`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

const getEventById = async (eventId) => {
  const token = sessionStorage.getItem("userToken");

  try {
    const response = await axios.get(
      `${BASE_URL}/api/event/subEvent/${eventId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

const saveEvent = async (eventDetails) => {
  const token = sessionStorage.getItem("userToken");

  try {
    const response = await axios.post(
      `${BASE_URL}/api/event/subEvent/set`,
      {
        ...eventDetails,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

const cancelEvent = async (eventId) => {
  const token = sessionStorage.getItem("userToken");

  try {
    const response = await axios.get(
      `${BASE_URL}/api/event/subEvent/${eventId}/cancel`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

const getGuestListByEventId = async (eventId) => {
  const token = sessionStorage.getItem("userToken");

  try {
    const response = await axios.get(
      `${BASE_URL}/api/event/subEvent/${eventId}/person/list`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

const inviteGuestsByEventId = async (eventId, formData) => {
  const token = sessionStorage.getItem("userToken");

  try {
    const response = await axios.post(
      `${BASE_URL}/api/event/subEvent/${eventId}/invite`,
      {
        ...formData,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

const unInviteGuestsByEventId = async (eventId, formData) => {
  const token = sessionStorage.getItem("userToken");

  try {
    const response = await axios.post(
      `${BASE_URL}/api/event/subEvent/${eventId}/un-invite`,
      {
        ...formData,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

const sendEventRemainder = async (eventId) => {
  const token = sessionStorage.getItem("userToken");

  try {
    const response = await axios.post(
      `${BASE_URL}/api/event/subEvent/${eventId}/sendEmail/remainder`,
      null,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

const sendThankyouEmail = async (eventId) => {
  const token = sessionStorage.getItem("userToken");

  try {
    const response = await axios.post(
      `${BASE_URL}/api/event/subEvent/${eventId}/sendEmail/thankYou`,
      null,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

const notifyGuests = async (formData) => {
  const token = sessionStorage.getItem("userToken");

  try {
    const response = await axios.post(
      `${BASE_URL}/api/event/notify`,
      {
        ...formData,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

const cancelEventById = async (eventId) => {
  const token = sessionStorage.getItem("userToken");

  try {
    const response = await axios.get(
      `${BASE_URL}/api/event/subEvent/${eventId}/cancel`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

const updatedEventStatusForGuests = async (eventId, status, formData) => {
  const token = sessionStorage.getItem("userToken");

  try {
    const response = await axios.post(
      `${BASE_URL}/api/event/subEvent/${eventId}/admin/${status}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

const sendMessageFromEvent = async (formData) => {
  const token = sessionStorage.getItem("userToken");

  try {
    const response = await axios.post(
      `${BASE_URL}/api/conversation/subEvent/sendMessage`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export {
  getEventList,
  getGuestList,
  getGuestListByEventId,
  getEventById,
  getGroupList,
  saveEvent,
  saveGroupDetails,
  saveGuestDetails,
  inviteGuestsByEventId,
  unInviteGuestsByEventId,
  cancelEvent,
  assignGroupLeader,
  sendEventRemainder,
  sendThankyouEmail,
  cancelEventById,
  notifyGuests,
  updatedEventStatusForGuests,
  sendMessageFromEvent
};
