import "./faq.css";
import { FAQ_LIST } from "../../utils/constants";
import Chatbot from "../chatbot";

const FAQ = () => {
  return (
    <div className="faq-container">
      <h1 className="faq-title">Frequenty Asked Questions</h1>
      {FAQ_LIST.map((faqListItem) => {
        return (
          <div className="faq-q-and-a-container" key={faqListItem.id}>
            <p className="faq-question">{faqListItem.question}</p>
            <p className="faq-answer">{faqListItem.answer}</p>
          </div>
        );
      })}
      {/* <div className="chatbot-wrapper">
          <Chatbot />
        </div> */}
    </div>
  );
};

export default FAQ;
