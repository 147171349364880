import "./index.css";
import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  OutlinedInput,
  Select,
  Tooltip,
} from "@mui/material";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import AddGuest from "../../assets/AddGuest.svg";
import { useDispatch, useSelector } from "react-redux";
import { hideLoadingBar, openSidePanel, showLoadingBar } from "../../actions/actions";
import { MORE_ACTION_TYPE } from "../../utils/configs";
import { TABLE_TAB_TYPE } from "../../utils/constants";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import { useState } from "react";
import { notifyGuests } from "../../services/adminService";
import { toast } from "react-toastify";

const DashboardFilters = ({
  filtersData,
  selectedTab,
  eventList,
  groupList,
  handleFiltersChange,
}) => {
  const selectedDataReducer = useSelector((state) => state.selectedDataReducer);

  const selectedGuestList = selectedDataReducer.selectedGuestList;
  const dispatch = useDispatch();

  const [moreActionsElement, setMoreActionsElement] = useState(null);
  const isMoreActionsMenuOpen = Boolean(moreActionsElement);

  const handleMoreActionsMenuToggle = (event = null) => {
    setMoreActionsElement(event?.currentTarget);
  };

  const handleOpenSidePanel = (actionType) => {
    dispatch(openSidePanel(actionType, null));
  };

  const getData = (key) => {
    return filtersData[selectedTab][key];
  };

  const sendNotificationToGuests = async () => {
    const formData = {
      idList: [],
    };

    selectedGuestList.forEach((listItem) => {
      formData.idList.push(listItem.id);
    });
    dispatch(showLoadingBar());
    try {
      await notifyGuests(formData);
      handleMoreActionsMenuToggle();
      dispatch(hideLoadingBar());
      toast.success("Notifications have been sent to guests successfully.");
    } catch (error) {
      dispatch(hideLoadingBar());
      toast.error(error.response.data.message);
    }
  };

  return (
    <>
      <Menu
        id="long-menu"
        anchorEl={moreActionsElement}
        open={isMoreActionsMenuOpen}
        onClose={() => handleMoreActionsMenuToggle()}
      >
        <MenuItem
          onClick={() => {
            handleOpenSidePanel(MORE_ACTION_TYPE.GUEST_INVITE);
            handleMoreActionsMenuToggle();
          }}
        >
          Invite
        </MenuItem>
        <MenuItem
          onClick={sendNotificationToGuests}
        >
          Notify Guests
        </MenuItem>
      </Menu>
      <div className="filters-container">
        <div className="select-container">
          <FormControl fullWidth size="small">
            <Select
              id="eventId"
              name="eventId"
              value={getData("eventId")}
              placeholder="Event"
              displayEmpty
              className="custom-select"
              onChange={handleFiltersChange}
            >
              <MenuItem value="" className="default-option">
                All Events
              </MenuItem>
              {eventList?.map((listItem) => {
                return (
                  <MenuItem key={`Group_${listItem.id}`} value={listItem.id}>
                    {listItem.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <FormControl fullWidth size="small">
            <Select
              id="groupId"
              name="groupId"
              value={getData("groupId")}
              placeholder="Group"
              displayEmpty
              className="custom-select"
              onChange={handleFiltersChange}
            >
              <MenuItem value="" className="default-option">
                All Group
              </MenuItem>
              {groupList?.map((listItem) => {
                return (
                  <MenuItem key={`Group_${listItem.id}`} value={listItem.id}>
                    {listItem.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>

        <div className="create-buttons-container">
          <Button
            variant="contained"
            className="create-button"
            startIcon={<AddRoundedIcon className="highlight-text" />}
            onClick={() => {
              handleOpenSidePanel(MORE_ACTION_TYPE.EVENT_CREATE);
            }}
          >
            Create Event
          </Button>

          <Button
            variant="contained"
            className="create-button"
            startIcon={<AddRoundedIcon className="highlight-text" />}
            onClick={() => {
              handleOpenSidePanel(MORE_ACTION_TYPE.GROUP_CREATE);
            }}
          >
            Create Group
          </Button>
        </div>

        <div className="search-container">
          <FormControl fullWidth size="small">
            <OutlinedInput
              id="searchText"
              value={getData("searchText")}
              onChange={handleFiltersChange}
              name="searchText"
              className="search-text-control"
              size="small"
              placeholder="Search"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton edge="end">
                    <SearchOutlinedIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </div>

        <div className="create-buttons-container">
          <Button
            variant="contained"
            className="add-invite-button"
            onClick={() => {
              handleOpenSidePanel(MORE_ACTION_TYPE.GUEST_CREATE);
            }}
          >
            <span>
              <img src={AddGuest} alt="Add Guest" />
              Add Guest
            </span>
          </Button>

          {selectedTab === TABLE_TAB_TYPE.GUEST ? (
            <>
              <Tooltip title="More Actions" arrow placement="bottom">
                <IconButton
                  onClick={handleMoreActionsMenuToggle}
                  disabled={!selectedGuestList.length}
                >
                  <MenuRoundedIcon />
                </IconButton>
              </Tooltip>
            </>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default DashboardFilters;
