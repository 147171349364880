import './noContent.css';
import NoMessage from '../../../assets/Messages/NoMessage.svg'


const NoMessages = () => {
    return <div className='no-content-main-container'>
        <div className='no-content-image-container'>
            <img src={NoMessage} alt='No Messages' loading='lazy' className='no-content-image' />
        </div>
        <div className='no-content-description-container'>
            <span className='no-content-description'>There are no messages in this chat</span>
            <span className='no-content-sub-text'>Start a conversation to see messages here</span>
        </div>
    </div>
}

export default NoMessages;