// rootReducer.js
import { combineReducers } from 'redux';
import sidePanelReducer from './sidePanelReducer';
import masterDataReducer from './masterDataReducer';
import selectedDataReducer from './selectedDataReducer';
import userInfoReducer from './userInfoReducer';
import refreshReducer from './refreshReducer';
import messagingReducer from './messagingReducer';

const rootReducer = combineReducers({
    sidePanelReducer, 
    masterDataReducer,
    selectedDataReducer,
    userInfoReducer,
    refreshReducer,
    messagingReducer
});

export default rootReducer;
