import { MORE_ACTION_TYPE } from "../../utils/configs";
import AddEventForm from "./Forms/addEventForm";
import AddGroupForm from "./Forms/addGroupForm";
import AddGuestForm from "./Forms/addGuestForm";
import MessageForm from "./Forms/messageForm";
import EventStatusForm from "./Forms/eventStatusForm";
import InviteGuests from "./Forms/inviteGuest";
import "./index.css";

const SidePanelComponent = ({ sidePanelType, selectedData, closePanel }) => {
  const getPanelComponent = () => {
    switch (sidePanelType) {
      case MORE_ACTION_TYPE.GUEST_EDIT:
      case MORE_ACTION_TYPE.GUEST_CREATE:
        return (
          <AddGuestForm closePanel={closePanel} guestDetails={selectedData} />
        );
      case MORE_ACTION_TYPE.EVENT_CREATE:
      case MORE_ACTION_TYPE.EVENT_EDIT:
        return (
          <AddEventForm closePanel={closePanel} eventDetails={selectedData} />
        );
      case MORE_ACTION_TYPE.GROUP_CREATE:
        return (
          <AddGroupForm closePanel={closePanel} groupDetails={selectedData} />
        );
      case MORE_ACTION_TYPE.GUEST_INVITE:
        return <InviteGuests closePanel={closePanel} />;

      case MORE_ACTION_TYPE.EVENT_STATUS_UPDATE:
        return <EventStatusForm closePanel={closePanel} />;
      case MORE_ACTION_TYPE.SEND_BROADCAST_MESSAGE:
        return (
          <MessageForm
            closePanel={closePanel}
            eventDetails={selectedData}
          />
        );
      default:
        return <></>;
    }
  };
  return <>{getPanelComponent()}</>;
};

export default SidePanelComponent;
