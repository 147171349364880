import axios from "axios";

const BASE_URL = process.env.REACT_APP_ALT_BASE_SERVER_URL;

const getPendingInvites = async () => {
  const token = sessionStorage.getItem("userToken");

  try {
    const response = await axios.get(
      `${BASE_URL}/api/event/invitations/pending`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

const getAcceptedInvites = async () => {
  const token = sessionStorage.getItem("userToken");

  try {
    const response = await axios.get(
      `${BASE_URL}/api/event/invitations/accepted`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

const getInvites = async () => {
  const token = sessionStorage.getItem("userToken");

  try {
    const response = await axios.get(
      `${BASE_URL}/api/event/invitations`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};


const updatedEventStatus = async (eventId, status, formData) => {
  const token = sessionStorage.getItem("userToken");

  try {
    const response = await axios.post(
      `${BASE_URL}/api/event/subEvent/${eventId}/acceptDecline/${status}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

const getDependentInvites = async () => {
  const token = sessionStorage.getItem("userToken");

  try {
    const response = await axios.get(
      `${BASE_URL}/api/event/dependant/subEvent/list`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

const getDependentsByEventId = async (eventId) => {
  const token = sessionStorage.getItem("userToken");

  try {
    const response = await axios.get(
      `${BASE_URL}/api/event/subEvent/${eventId}/dependants`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

const updateDependentsEventStatus = async (eventId, status, formData) => {
  const token = sessionStorage.getItem("userToken");

  try {
    const response = await axios.post(
      `${BASE_URL}/api/event/subEvent/${eventId}/dependants/${status}`,
      {
        ...formData,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export {
  getPendingInvites,
  getAcceptedInvites,
  getInvites,
  updatedEventStatus,
  getDependentInvites,
  getDependentsByEventId,
  updateDependentsEventStatus,
};
