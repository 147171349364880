import { useEffect, useState } from "react";
import "./guestStyles.css";
import { getAcceptedInvites } from "../../../services/guestService";
import { toast } from "react-toastify";
import { Grid } from "@mui/material";
import EventCard from "../common/EventCard";
import { useDispatch } from "react-redux";
import { hideLoadingBar, showLoadingBar } from "../../../actions/actions";

const GuestEventList = () => {
  const [rsvpEvents, setRsvpEvents] = useState([]);
  const dispatch = useDispatch()

  const fetchInvites = async () => {
    setRsvpEvents([]);
    dispatch(showLoadingBar())
    try {
      const response = await getAcceptedInvites();
      setRsvpEvents(response?.data ?? []);
      dispatch(hideLoadingBar())
    } catch (error) {
      dispatch(hideLoadingBar())
      toast.error(error.response.data.message);
    }
  };

  useEffect(() => {
    fetchInvites();
  }, []);

  const handleRefresh = () => {
    fetchInvites();
  }

  return (
    <div className="guest-event-list-root">
      {rsvpEvents?.length ? (
        <>
          <span className="guest-event-heading">My Events</span>
          <Grid container className="guest-event-list-container" spacing={3} alignItems={'stretch'}>
            {rsvpEvents.map((eventData) => {
              return (
                <Grid item xs={12} md={6} lg={4}>
                  <EventCard eventData={eventData} handleRefresh={handleRefresh} />
                </Grid>
              );
            })}
          </Grid>
        </>
      ) : (
        <div className="no-data-text">No Active Events</div>
      )}
    </div>
  );
};

export default GuestEventList;
