import DateTimePicker from "react-datetime-picker";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import "./index.css";
import dayjs from "dayjs";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';

const CustomDateTimePicker = ({ id, name, value, onChange, onBlur }) => {
  const handleDateTimeChange = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD HH:mm:ss");
    onChange({
      target: {
        name,
        value: date ? formattedDate : "",
      },
    });
  };

  const getValue = () => {
    return value ? dayjs(value) : "";
  };

  return (
    <>
      <DateTimePicker
        name={name}
        value={getValue()}
        onChange={handleDateTimeChange}
        disableClock={true}
        format="MM-dd-yyyy h:mm a"
        onBlur={onBlur}
        className="date-time-picker"
        calendarIcon={<CalendarMonthIcon />}
        clearIcon={<ClearRoundedIcon />}
      />
    </>
  );
};

export default CustomDateTimePicker;
