const FIELD_TYPE_ENUMS = {
  STRING: "STRING",
  DATE: "DATE",
  DATE_TIME: "DATE_TIME",
  NUMBER: "NUMBER",
};

const MORE_ACTION_TYPE = {
  GUEST_CREATE: "GUEST_CREATE",
  GUEST_EDIT: "GUEST_EDIT",
  GUEST_DELETE: "GUEST_DELETE",
  GUEST_INVITE: "GUEST_INVITE",
  EVENT_EDIT: "EVENT_EDIT",
  EVENT_DELETE: "EVENT_DELETE",
  EVENT_CREATE: "EVENT_CREATE",
  GROUP_CREATE: "GROUP_CREATE",
  EVENT_GUEST_INVITE: "EVENT_GUEST_INVITE",
  CANCEL_EVENT: "CANCEL_EVENT",
  EVENT_REMAINDER: "EVENT_REMAINDER",
  SEND_THANK_YOU: "SEND_THANK_YOU",
  NOTIFY_GUESTS: "NOTIFY_GUESTS",
  EVENT_STATUS_UPDATE: 'EVENT_STATUS_UPDATE',
  SEND_BROADCAST_MESSAGE: 'SEND_BROADCAST_MESSAGE'
};

// Config to show the dynamic table
// Width for checkbox and actions is 5% by default

const GUEST_TABLE_CONFIG = {
  tableName: "Guest List",
  tableType: "GUEST_LIST",
  columnConfigs: [
    {
      label: "Name",
      fieldType: FIELD_TYPE_ENUMS.STRING,
      fieldKey: "name",
      align: "left",
      width: "20%",
    },
    {
      label: "Phone",
      fieldType: FIELD_TYPE_ENUMS.STRING,
      fieldKey: "phone",
      align: "left",
      width: "15%",
    },
    {
      label: "Email",
      fieldType: FIELD_TYPE_ENUMS.STRING,
      fieldKey: "email",
      align: "left",
      width: "20%",
    },
    {
      label: "Guest Type",
      fieldType: FIELD_TYPE_ENUMS.STRING,
      fieldKey: "guestTypeName",
      align: "center",
      width: "10%",
    },
    {
      label: "Group Name",
      fieldType: FIELD_TYPE_ENUMS.STRING,
      fieldKey: "groupName",
      align: "center",
      width: "15%",
    },
    {
      label: "Priority",
      fieldType: FIELD_TYPE_ENUMS.STRING,
      fieldKey: "priority",
      align: "center",
      width: "10%",
    },
  ],
  isCheckBox: true,
  isActions: true,
  actionItems: [
    {
      label: "Edit",
      type: MORE_ACTION_TYPE.GUEST_EDIT,
    },
    {
      label: "Invite",
      type: MORE_ACTION_TYPE.GUEST_INVITE,
    },
  ],
};

const EVENT_TABLE_CONFIG = {
  tableName: "Event List",
  tableType: "EVENT_LIST",
  columnConfigs: [
    {
      label: "Event Name",
      fieldType: FIELD_TYPE_ENUMS.STRING,
      fieldKey: "eventName",
      align: "left",
      width: "20%",
    },
    {
      label: "Date & Time",
      fieldType: FIELD_TYPE_ENUMS.STRING,
      fieldKey: "eventDateTime",
      align: "left",
      width: "25%",
    },
    {
      label: "Location",
      fieldType: FIELD_TYPE_ENUMS.STRING,
      fieldKey: "location",
      align: "left",
      width: "15%",
    },
    {
      label: "Number of Guests Attending",
      fieldType: FIELD_TYPE_ENUMS.STRING,
      fieldKey: "guestsAttending",
      align: "center",
      width: "20%",
    },
    {
      label: "Limit",
      fieldType: FIELD_TYPE_ENUMS.STRING,
      fieldKey: "guestLimit",
      align: "center",
      width: "10%",
    },
  ],
  isCheckBox: false,
  isActions: true,
  actionItems: [
    {
      label: "Edit",
      type: MORE_ACTION_TYPE.EVENT_EDIT,
    },
    {
      label: "Invite Guests",
      type: MORE_ACTION_TYPE.EVENT_GUEST_INVITE,
    },
    {
      label: "Cancel Event",
      type: MORE_ACTION_TYPE.CANCEL_EVENT,
    },
    {
      label: "Send a Reminder",
      type: MORE_ACTION_TYPE.EVENT_REMAINDER,
    },
    {
      label: "Send Thank You Email",
      type: MORE_ACTION_TYPE.SEND_THANK_YOU,
    },
    {
      label: "Send Broadcast Message",
      type: MORE_ACTION_TYPE.SEND_BROADCAST_MESSAGE,
    },
  ],
};

const EVENT_GUEST_INVITE_MORE_ACTIONS = [
  {
    label: "Edit",
    type: MORE_ACTION_TYPE.EVENT_EDIT,
  },
  {
    label: "Invite Guests",
    type: MORE_ACTION_TYPE.EVENT_GUEST_INVITE,
  },
];

const TABLE_TYPE = {
  EVENT: "EVENT_LIST",
  GUEST: "GUEST_LIST",
};

export {
  FIELD_TYPE_ENUMS,
  GUEST_TABLE_CONFIG,
  EVENT_TABLE_CONFIG,
  MORE_ACTION_TYPE,
  EVENT_GUEST_INVITE_MORE_ACTIONS,
  TABLE_TYPE,
};
