import "./guestStyles.css";
import QRSCanner from "../../../assets/QR_Scanner.png";

const Registry = () => {
  return (
    <div className="landing-root">
      <div className="landing-details-section">
        <span className="landing-details-heading registry-heading">
          Our Registry
        </span>
        <span className="landing-details">
          We are overjoyed to have you join us on our special day! The most
          important thing to us is that you're able to come and celebrate our
          wedding. However, if you wish to give a gift, we would graciously
          accept a contribution to our newly weds fund. We kindly request no
          boxed gifts.
        </span>
      </div>

      <div className="landing-details-section">
        <span className="landing-details-heading registry-heading">Venmo</span>
        <img
          src={QRSCanner}
          alt="QR-Scanner"
          loading="lazy"
          className="qr-scanner-image"
        />
        <span className="landing-details">
          You can easily send your gift via Venmo! Just scan the QR code above
          or find Vishnu on Venmo <strong>@Vishnu-Razdan</strong>. If prompted
          for a security code, the last 4 digits of the phone number are{" "}
          <strong>8800</strong>.
        </span>
      </div>

      <div className="landing-details-section">
        <span className="landing-details-heading registry-heading">Zelle</span>
        <span className="landing-details">
          For those who prefer using Zelle, you can send your gift directly to
          our Zelle account. Simply use the phone number <strong>443-474-8800</strong> to find
          us.
          <br />
          <br />
          Thank you for being a part of our journey. We can't wait to celebrate
          with you!
        </span>
      </div>

      <div className="landing-details-section">
        <span className="landing-details-heading registry-heading">
          Cash Gifts or Cheques
        </span>
        <span className="landing-details">
          We will also have a gifts table during the wedding for those who
          prefer a non-electronic option.
        </span>
      </div>
    </div>
  );
};

export default Registry;
