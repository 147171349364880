import { Avatar } from "@mui/material";
import "./index.css";
import { getContactAvatarLogo } from "../../../utils/commonMethods";
import { updateSelectedContact } from "../../../actions/actions";
import { useDispatch } from "react-redux";

import Announcements from "../../../assets/Messages/Announcements.svg";
import Conversations from "../../../assets/Messages/Conversations.svg";
import { CONVERSATION_TYPE_ENUM } from "../../../utils/constants";

const ContactTile = ({ contactDetails, handleConversationSelect }) => {
  const dispatch = useDispatch();

  const handleContactClick = () => {
    dispatch(updateSelectedContact(contactDetails));
    handleConversationSelect(contactDetails.id);
  };

  const getContactSrc = () => {
    // conversationTypeEnum

    switch (contactDetails.conversationTypeEnum) {
      case CONVERSATION_TYPE_ENUM.ANNOUNCEMENTS:
        return Announcements;
      case CONVERSATION_TYPE_ENUM.GROUP:
        return Conversations;
      default:
        return null;
    }
  };

  return (
    <div className="contact-tile-root" onClick={handleContactClick}>
      <div className="contact-avatar-container">
        <img
          src={getContactSrc()}
          alt={contactDetails.conversationTypeEnum}
          height={56}
          width={56}
        />
      </div>
      <div className="contact-name-container">
        <span className="contact-name">{contactDetails.name}</span>
        <span className="contact-description">
          {contactDetails.lastMessage?.content}
        </span>
      </div>

      {!!contactDetails.unReadMessageCount ? (
        <div className="unread-count-badge">
          {contactDetails.unReadMessageCount}
        </div>
      ) : null}
    </div>
  );
};

export default ContactTile;
