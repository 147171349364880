import { ACTION_TYPE_ENUMS } from "../actions/actionTypes";

const initialState = {
  isSidePanelOpen: false,
  sidePanelType: null,
  selectedData: null,
};

const sidePanelReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPE_ENUMS.OPEN_SIDE_PANEL:
      return {
        ...state,
        isSidePanelOpen: true,
        sidePanelType: action.payload.sidePanelType,
        selectedData: action.payload.selectedData,
      };
    case ACTION_TYPE_ENUMS.CLOSE_SIDE_PANEL:
      return {
        ...state,
        isSidePanelOpen: false,
        sidePanelType: null,
        selectedData: null,
      };
    default:
      return state;
  }
};

export default sidePanelReducer;
