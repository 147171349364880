const MOCK_GUEST_TEST_DATA = [
  {
    id: 10001,
    fullName: "Edward",
    gender: "MALE",
    ageGroup: "ADULT",
    countryCode: "+1",
    mobile: "5555551234",
    emailId: "edward_198o@gmail.com",
    guestTypeEnum: "BRIDE",
    guestTypeName: "Bride",
    groupName: "Paul-Family",
    priority: "HIGH",
    isInvited: true,
  },
  {
    id: 10002,
    fullName: "Christopher",
    gender: "MALE",
    ageGroup: "YOUTH",
    countryCode: "+1",
    mobile: "5555551234",
    emailId: "edward_198o@gmail.com",
    guestTypeEnum: "BRIDE",
    guestTypeName: "Bride",
    groupName: "Jack",
    priority: "MEDIUM",
    isInvited: false,
  },
  {
    id: 10003,
    fullName: "Jeffery Ryan",
    gender: "MALE",
    ageGroup: "CHILD",
    countryCode: "+1",
    mobile: "5555551234",
    emailId: "edward_198o@gmail.com",
    guestTypeEnum: "BRIDE",
    guestTypeName: "Bride",
    groupName: "Jack",
    priority: "LOW",
    isInvited: false,
  },
  {
    id: 10004,
    fullName: "Edward",
    gender: "MALE",
    ageGroup: "ADULT",
    countryCode: "+1",
    mobile: "5555551234",
    emailId: "edward_198o@gmail.com",
    guestTypeEnum: "GROOM",
    guestTypeName: "GROOM",
    groupName: "Frank",
    priority: "HIGH",
    isInvited: true,
  },
  {
    id: 10005,
    fullName: "Edward",
    gender: "MALE",
    ageGroup: "ADULT",
    countryCode: "+1",
    mobile: "5555551234",
    emailId: "edward_198o@gmail.com",
    guestTypeEnum: "BRIDE",
    guestTypeName: "Bride",
    groupName: "Paul-Family",
    priority: "HIGH",
    isInvited: false,
  },
  {
    id: 10006,
    fullName: "Edward",
    gender: "MALE",
    ageGroup: "ADULT",
    countryCode: "+1",
    mobile: "5555551234",
    emailId: "edward_198o@gmail.com",
    guestTypeEnum: "BRIDE",
    guestTypeName: "Bride",
    groupName: "Paul-Family",
    priority: "HIGH",
    isInvited: false,
  },
  {
    id: 10007,
    fullName: "Edward",
    gender: "MALE",
    ageGroup: "ADULT",
    countryCode: "+1",
    mobile: "5555551234",
    emailId: "edward_198o@gmail.com",
    guestTypeEnum: "BRIDE",
    guestTypeName: "Bride",
    groupName: "Paul-Family",
    priority: "HIGH",
    isInvited: false,
  },
  {
    id: 10008,
    fullName: "Edward",
    gender: "MALE",
    ageGroup: "ADULT",
    countryCode: "+1",
    mobile: "5555551234",
    emailId: "edward_198o@gmail.com",
    guestTypeEnum: "BRIDE",
    guestTypeName: "Bride",
    groupName: "Paul-Family",
    priority: "HIGH",
    isInvited: false,
  },
  {
    id: 10009,
    fullName: "Edward",
    gender: "MALE",
    ageGroup: "ADULT",
    countryCode: "+1",
    mobile: "5555551234",
    emailId: "edward_198o@gmail.com",
    guestTypeEnum: "BRIDE",
    guestTypeName: "Bride",
    groupName: "Paul-Family",
    priority: "HIGH",
    isInvited: false,
  },
  {
    id: 10010,
    fullName: "Edward",
    gender: "MALE",
    ageGroup: "ADULT",
    countryCode: "+1",
    mobile: "5555551234",
    emailId: "edward_198o@gmail.com",
    guestTypeEnum: "GROOM",
    guestTypeName: "Groom",
    groupName: "Paul-Family",
    priority: "HIGH",
    isInvited: false,
  },
  {
    id: 10011,
    fullName: "Edward",
    gender: "MALE",
    ageGroup: "ADULT",
    countryCode: "+1",
    mobile: "5555551234",
    emailId: "edward_198o@gmail.com",
    guestTypeEnum: "BRIDE",
    guestTypeName: "Bride",
    groupName: "Paul-Family",
    priority: "HIGH",
    isInvited: false,
  },
];

const MOCK_EVENT_DATA = [
  {
    eventName: "Haldi",
    startTime: "12 April 2024, 9:32 AM",
    endTime: "13 April 2024, 6:32 PM",
    location: "Hamilton, Virginia",
    guestsAttending: "84",
    guestsInvited: "200",
    limit: "250",
  },
  {
    eventName: "Sangeet",
    startTime: "12 April 2024, 9:32 AM",
    endTime: "13 April 2024, 6:32 PM",
    location: "Hamilton, Virginia",
    guestsAttending: "84",
    guestsInvited: "200",
    limit: "250",
  },
  {
    eventName: "Mehandi",
    startTime: "12 April 2024, 9:32 AM",
    endTime: "13 April 2024, 6:32 PM",
    location: "Hamilton, Virginia",
    guestsAttending: "84",
    guestsInvited: "200",
    limit: "250",
  },
  {
    eventName: "Tilak",
    startTime: "12 April 2024, 9:32 AM",
    endTime: "13 April 2024, 6:32 PM",
    location: "Hamilton, Virginia",
    guestsAttending: "84",
    guestsInvited: "200",
    limit: "250",
  },
];

const MOCK_RSVP_EVENT_LIST = [
  {
    id: 10001,
    eventName: "Sangeeth",
    eventDate: "30 June 2024",
    respondByDate: "14 March 2024",
  },
  {
    id: 10002,
    eventName: "Haldi",
    eventDate: "30 June 2024",
    respondByDate: "14 March 2024",
  },
];

const GENDER_TYPE_LABEL = {
  MALE: "M",
  FEMALE: "F",
};

const AGE_GROUP_LABEL = {
  ADULT: "Adult",
  CHILD: "Child",
  YOUTH: "Youth",
};

const TABLE_TAB_TYPE = {
  GUEST: "GUEST",
  EVENTS: "EVENTS",
};

const TABLE_TAB_ITEMS = [
  {
    label: "Guest List",
    type: TABLE_TAB_TYPE.GUEST,
  },
  {
    label: "Events List",
    type: TABLE_TAB_TYPE.EVENTS,
  },
];

const EVENT_TITLES = {
  GUEST_CREATE: "Create a Guest",
  GUEST_EDIT: "Edit Guest",
  GUEST_DELETE: "Delete Guest",
  GUEST_INVITE: "Invite",
  EVENT_EDIT: "Edit an Event",
  EVENT_DELETE: "Delete Event",
  EVENT_CREATE: "Create Event",
  GROUP_CREATE: "Create Group",
  EVENT_STATUS_UPDATE: "Update Event Status",
  SEND_BROADCAST_MESSAGE: "Send a Message",
};

const FORM_VALIDATION_MESSAGES = {
  FULLNAME_REQUIRED: "Full name is required",
  GENDER_REQUIRED: "Gender is required",
  AGE_GROUP_REQUIRED: "Age Group is required",
  PHONE_REQUIRED: "Mobile number is required",
  EMAIL_REQUIRED: "Email is required",
  EMAIL_VALIDATION: "Please enter valid Email Id",
  COUNTRY_CODE_REQUIRED: "Country Code is required",
  GUEST_TYPE_REQUIRED: "GuestType is required",
  PRIORITY_REQUIRED: "Priority is required",
  EVENT_TYPE_REQUIRED: "Event Type is required",
  EVENT_NAME_REQUIRED: "Event Name is required",
  EVENT_START_TIME_REQUIRED: "Event Start Date and Time are required",
  EVENT_END_TIME_REQUIRED: "Event End Date and Time are required",
  EVENT_LOCATION_REQUIRED: "Event Location is required",
  EVENT_GUEST_LIMIT_REQUIRED: "Event Guest Limit is required",
  GROUP_NAME_REQUIRED: "Group Name is required",
  SELECT_EVENT: "Please select a event to send Invites",
  RESPONSE_END_TIME_REQUIRED: "Respond By Date and Time are required",
  MESSAGE_TYPE_REQUIRED: "Type of Message is required.",
  RECEIPIENTS_REQUIRED: "List of Receipients is required",
  MESSAGE_REQUIRED: "Please enter a message",
};

const CONSENT_MESSAGES = {
  phone:
    "I consent to receive notifications on event details, passcode for login and other event messages from the host to your login or phone. Message and data rates may apply",
};

const RSVP_EVENT_STATUS = {
  PENDING: "PENDING",
  ACCEPTED: "APPROVED",
  DECLINED: "DECLINED",
};

const USER_ROLES = {
  admin: "ADMIN",
  guest: "GUEST",
};

const RSVP_EVENT_STATUS_LIST = [
  {
    label: "Approved",
    typeEnum: RSVP_EVENT_STATUS.ACCEPTED,
  },
  {
    label: "Declined",
    typeEnum: RSVP_EVENT_STATUS.DECLINED,
  },
  {
    label: "Pending",
    typeEnum: RSVP_EVENT_STATUS.PENDING,
  },
];

const LANDING_PAGE_LINKS = {
  document:
    "https://docs.google.com/document/d/1ZqvGOPhPrw8Z8MszlvEGlSvVcdIwIcmdyKw9Wyg6mFQ/edit",
  hotel: "https://book.passkey.com/go/ALRA2024",
};

const FAQ_LIST = [
  {
    id: 10001,
    question: "Do you have any hotel recommendations?",
    answer: (
      <>
        We arranged for a room block at our venue! Please access the{" "}
        <b>"Travel"</b> page on our website for more information.
      </>
    ),
  },
  {
    id: 10002,
    question:
      "What's the difference between My Invites, My Events, and My Group Invites?",
    answer: (
      <>
        Great question!
        <br />
        My Invites shows all of your pending invitations for various events at
        our wedding! This is where you will go to accept or decline your
        invitation.
        <br /> My Events shows all of the events you are invited to. When you
        click on each event, it takes you to the specific event website with
        additional details like time, location, and dress code!
        <br /> My Group Invites is a special page only visible to the primary
        adult responsible for their family's RSVP. On this page you can update
        the RSVP for each individual member of your party, see all the events
        your family is invited to, and go to the specific event page for more
        details.
      </>
    ),
  },
  {
    id: 10003,
    question: "Who should we contact if we have any additional questions?",
    answer: (
      <>
        Please reach out to Mouna and Vishnu at <b>mishnu2024@gmail.com</b> and
        we'll get back to you as quickly as possible!
      </>
    ),
  },
];

const CONVERSATION_TYPE_ENUM = {
  ADMIN: "ADMIN",
  GROUP: "GROUP",
  PRIVATE: "PRIVATE",
  ANNOUNCEMENTS: "ANNOUNCEMENTS",
};

const MESSAGE_TYPE_ENUM = {
  BROADCAST: "BROADCAST",
  TWO_WAY: "TWO_WAY",
};

const RECEIPIENT_LIST_TYPE_ENUM = {
  ALL: "ALL",
  ACCEPTED: "ACCEPTED",
};

export {
  MOCK_GUEST_TEST_DATA,
  MOCK_EVENT_DATA,
  AGE_GROUP_LABEL,
  GENDER_TYPE_LABEL,
  TABLE_TAB_TYPE,
  TABLE_TAB_ITEMS,
  EVENT_TITLES,
  FORM_VALIDATION_MESSAGES,
  CONSENT_MESSAGES,
  MOCK_RSVP_EVENT_LIST,
  RSVP_EVENT_STATUS,
  USER_ROLES,
  RSVP_EVENT_STATUS_LIST,
  LANDING_PAGE_LINKS,
  FAQ_LIST,
  CONVERSATION_TYPE_ENUM,
  MESSAGE_TYPE_ENUM,
  RECEIPIENT_LIST_TYPE_ENUM,
};
