import axios from "axios";

const BASE_URL = process.env.REACT_APP_ALT_BASE_SERVER_URL;

const getOtp = async (userIdentity) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/api/requestOtp/${userIdentity}`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

const verifyOtp = async (formData) => {
  try {
    const response = await axios.post(`${BASE_URL}/api/verifyOtp`, {
      ...formData,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

const getLoggedInUser = async () => {
  const token = sessionStorage.getItem("userToken");

  try {
    const response = await axios.get(`${BASE_URL}/api/person/loggedInUser`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

const getPriorityTypeList = async () => {
  const token = sessionStorage.getItem("userToken");

  try {
    const response = await axios.get(
      `${BASE_URL}/api/person/priorityType/list`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

const getEventTypeList = async () => {
  const token = sessionStorage.getItem("userToken");

  try {
    const response = await axios.get(
      `${BASE_URL}/api/event/subEventType/list`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

const getConversations = async () => {
  const token = sessionStorage.getItem("userToken");

  try {
    const response = await axios.get(`${BASE_URL}/api/conversation/myChats`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

const getMessagesById = async (conversationid) => {
  const token = sessionStorage.getItem("userToken");

  try {
    const response = await axios.get(
      `${BASE_URL}/api/conversation/${conversationid}/getMessages`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

const sendMessage = async (formData) => {
  const token = sessionStorage.getItem("userToken");

  try {
    const response = await axios.post(
      `${BASE_URL}/api/conversation/sendMessage`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

const readAllMessageByConversationId = async (conversationId) => {
  const token = sessionStorage.getItem("userToken");

  try {
    const response = await axios.post(
      `${BASE_URL}/api/conversation/${conversationId}/readMessages`,
      null,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export {
  getOtp,
  verifyOtp,
  getLoggedInUser,
  getPriorityTypeList,
  getEventTypeList,
  getConversations,
  getMessagesById,
  sendMessage,
  readAllMessageByConversationId
};
