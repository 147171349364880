import { useEffect, useState } from "react";
import "./guestStyles.css";
import RsvpEventDetails from "../common/RsvpEventDetails";
import { Tab, Tabs } from "@mui/material";
import { TabContext, TabPanel } from "@mui/lab";
import { getInvites, getPendingInvites } from "../../../services/guestService";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { hideLoadingBar, showLoadingBar } from "../../../actions/actions";

const GuestRsvpEventList = () => {
  const [rsvpEvents, setRsvpEvents] = useState([]);
  const [selectedEventId, setSelectedEventId] = useState(null);

  const dispatch = useDispatch();

  const isMultipleEvents = () => {
    return rsvpEvents.length > 1;
  };

  const fetchPendingInvites = async () => {
    setRsvpEvents([]);
    dispatch(showLoadingBar());
    try {
      const response = await getInvites();
      const eventList = response?.data ?? [];
      setRsvpEvents(eventList);
      if (eventList.length) {
        setSelectedEventId(eventList[0].webSubEvent.id);
      }
      dispatch(hideLoadingBar());
    } catch (error) {
      dispatch(hideLoadingBar());
      toast.error(error.response.data.message);
    }
  };

  useEffect(() => {
    fetchPendingInvites();
  }, []);

  const handleEventTabChange = (event, newValue) => {
    setSelectedEventId(newValue);
  };

  const showRsvpEvents = () => {
    return isMultipleEvents() ? (
      <>
        <TabContext value={selectedEventId}>
          <Tabs
            value={selectedEventId}
            onChange={handleEventTabChange}
            indicatorColor="secondary"
            variant="scrollable"
            className="rsvp-event-tabs-container"
          >
            {rsvpEvents.map((rsvpEvent) => {
              return (
                <Tab
                  className="rsvp-event-tab"
                  label={rsvpEvent.webSubEvent.name}
                  key={`event_${rsvpEvent.webSubEvent.id}`}
                  value={rsvpEvent.webSubEvent.id}
                />
              );
            })}
          </Tabs>
          {rsvpEvents.map((rsvpEvent) => {
            return (
              <TabPanel
                key={`eventPanel_${rsvpEvent.webSubEvent.id}`}
                value={rsvpEvent.webSubEvent.id}
              >
                <RsvpEventDetails eventDetails={rsvpEvent} />
              </TabPanel>
            );
          })}
        </TabContext>
      </>
    ) : (
      <>
        <RsvpEventDetails eventDetails={rsvpEvents[0]} />
      </>
    );
  };

  return (
    <div>
      {!rsvpEvents?.length ? (
        <div className="no-data-text">No Pending Invites</div>
      ) : (
        showRsvpEvents()
      )}
    </div>
  );
};

export default GuestRsvpEventList;
