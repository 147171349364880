import { useEffect, useState } from "react";
import "./guestStyles.css";
import { getEventById } from "../../../services/adminService";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import { getDateSuffix } from "../../../services/formHelperService";
import { useDispatch } from "react-redux";
import { hideLoadingBar, showLoadingBar } from "../../../actions/actions";
import { getDefaultTimeFormatter } from "../../../utils/commonMethods";

const EventDetails = () => {
  const [eventDetails, setEventDetails] = useState(null);
  const { id } = useParams();
  const dispatch = useDispatch();

  const fetchEvent = async () => {
    setEventDetails([]);
    dispatch(showLoadingBar());
    try {
      const response = await getEventById(id);
      setEventDetails(response?.data ?? []);
      dispatch(hideLoadingBar());
    } catch (error) {
      dispatch(hideLoadingBar());
      toast.error(error.response.data.message);
    }
  };

  const formatDate = (date) => {
    return `${dayjs(date).format("MMMM D")}${getDateSuffix(date)}`;
  };

  const handleOpenAttireLink = () => {
    window.open(eventDetails?.attireLink, "__blank");
  };

  useEffect(() => {
    fetchEvent();
  }, []);

  return (
    <div className="event-details-root">
      <div className="event-details-heading-container">
        <span className="event-details-name">{eventDetails?.name}</span>
        <span className="event-details-date">
          {formatDate(eventDetails?.startDate)}
        </span>
      </div>

      <div className="event-details-container">
        <span className="event-details-heading">Event Details</span>

        <div className="event-details-section">
          <span className="label">When</span>
          <span>{formatDate(eventDetails?.startDate)}</span>
        </div>

        <div className="event-details-section">
          <span className="label">Timings</span>
          <span>
            {getDefaultTimeFormatter(eventDetails?.startDate)}&nbsp;-&nbsp;
            {getDefaultTimeFormatter(eventDetails?.endDate)}
          </span>
        </div>

        <div className="event-details-section">
          <span className="label">Where</span>
          <span>{eventDetails?.location}</span>
        </div>

        <div className="event-details-section">
          <span className="label">Dress Code</span>
          {eventDetails?.attireDescription ? (
            <span>
              {eventDetails?.attireDescription}
              <br />
              {eventDetails?.attireLink ? (
                <>
                  We received questions from our guests about the dress code for
                  this event.
                  <br />
                  You can find more information about the suggested dress code{" "}
                  <span className="attire-link" onClick={handleOpenAttireLink}>
                    here
                  </span>
                  .
                </>
              ) : null}
            </span>
          ) : (
            <span>---</span>
          )}
        </div>
      </div>
    </div>
  );
};

export default EventDetails;
