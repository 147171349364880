import { Paper } from "@mui/material";
import "./homepage.css";
import React from "react";
import Chatbot from '../chatbot';

const Homepage = () => {
    return (
        <div className="homepage-container">
            <h1 className="homepage-title">Welcome to our wedding website!</h1>
            <Paper variant="outlined" className="homepage-banner-wrapper">
                <img
                    alt="Vishnu Razdan and Mouna Allani's banner"
                    loading="lazy"
                    className="homepage-banner-image"
                    src="https://images.zola.com/ab52f20d-abba-4599-9656-2f4672d3b7f1"
                />
            </Paper>
            <h2 className="homepage-welcome-message">
                You are cordially invited <span>to celebrate the marriage of</span>
            </h2>
            <div className="homepage-welcome-name">
                <span>Vishnu Razdan</span>
                <span style={{"lineHeight": "1"}}>&nbsp;&&nbsp;</span>
                <span>Mouna Allani</span>
            </div>

            <div className="homepage-event-details">
                <div className="homepage-event-date">August 31, 2024</div>
                <div className="homepage-event-place">Leesburg, VA</div>
            </div>
            {/* <div className="chatbot-wrapper">
                <Chatbot />
            </div> */}
        </div>
    );
};

export default Homepage;