import { useFormik } from "formik";
import * as Yup from "yup";
import { FORM_VALIDATION_MESSAGES } from "../../../utils/constants";
import { Button, FormControl, FormHelperText, TextField } from "@mui/material";
import {
  getFormControlText,
  getFormControlValue,
  isFormControlError,
} from "../../../services/formHelperService";
import { saveGroupDetails } from "../../../services/adminService";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import {
  hideLoadingBar,
  refreshData,
  showLoadingBar,
} from "../../../actions/actions";
import "../index.css";

const AddGroupForm = ({ closePanel, groupDetails }) => {
  const dispatch = useDispatch();

  const initialValues = {
    id: getFormControlValue(groupDetails?.id),
    name: getFormControlValue(groupDetails?.name),
    description: getFormControlValue(groupDetails?.desciption),
  };

  const handleFormSubmit = (values) => {
    saveGroup(values);
  };

  const saveGroup = async (eventData) => {
    dispatch(showLoadingBar());
    try {
      await saveGroupDetails(eventData);
      dispatch(refreshData());
      closePanel();
      dispatch(hideLoadingBar());
      toast.success("Group Details have been saved successfully.");
    } catch (error) {
      dispatch(hideLoadingBar());
      toast.error(error.response.data.message);
    }
  };

  const eventForm = useFormik({
    initialValues,
    validationSchema: Yup.object({
      name: Yup.string().required(FORM_VALIDATION_MESSAGES.GROUP_NAME_REQUIRED),
      description: Yup.string(),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      handleFormSubmit(values);
    },
  });

  return (
    <div className="side-panel-root">
      <form className="form-container">
        <FormControl fullWidth size="small">
        <span className="form-control-label">Group Name *</span>
          <TextField
            id="name"
            name="name"
            className="form-input-control"
            size="small"
            placeholder="Group Name"
            value={eventForm.values.name}
            onChange={eventForm.handleChange}
            onBlur={eventForm.handleBlur}
          />
          {isFormControlError(eventForm, "name") && (
            <FormHelperText className="form-control-error">
              {getFormControlText(eventForm, "name")}
            </FormHelperText>
          )}
        </FormControl>

        <FormControl fullWidth size="small">
        <span className="form-control-label">Description</span>
          <TextField
            id="description"
            name="description"
            className="form-input-control"
            size="small"
            placeholder="Desciption"
            value={eventForm.values.description}
            onChange={eventForm.handleChange}
            onBlur={eventForm.handleBlur}
            multiline
            rows={3}
          />
          {isFormControlError(eventForm, "description") && (
            <FormHelperText className="form-control-error">
              {getFormControlText(eventForm, "description")}
            </FormHelperText>
          )}
        </FormControl>
      </form>

      <div className="form-actions-container">
        <Button
          variant="contained"
          className="cancel-button"
          onClick={closePanel}
        >
          Cancel
        </Button>

        <Button
          variant="contained"
          className="submit-button"
          onClick={eventForm.submitForm}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default AddGroupForm;
