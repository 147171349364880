import { useEffect, useState } from "react";
import { RSVP_EVENT_STATUS } from "../../../utils/constants";
import "../components/guestStyles.css";
import Sangeeth from "../../../assets/Sangeet.svg";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  TextField,
} from "@mui/material";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import {
  getDependentsByEventId,
  updateDependentsEventStatus,
} from "../../../services/guestService";
import CheckBoxRoundedIcon from "@mui/icons-material/CheckBoxRounded";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { hideLoadingBar, showLoadingBar } from "../../../actions/actions";
import { getDefaultTimeFormatter } from "../../../utils/commonMethods";

const GroupEventDetails = ({ eventData }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [groupMembers, setGroupMembers] = useState([]);
  const [selectedMemberList, setSelectedMemberList] = useState([]);

  const formatDate = (date) => {
    return dayjs(date).format("DD MMM, YYYY");
  };

  const [noteForm, setNoteForm] = useState({
    note: "",
  });

  const handleFormDataChange = (event) => {
    setNoteForm({
      ...noteForm,
      [event.target.name]: event.target.value,
    });
  };

  const fetchGroupMembers = async () => {
    dispatch(showLoadingBar());
    try {
      const response = await getDependentsByEventId(eventData.id);
      setGroupMembers(response?.data);
      dispatch(hideLoadingBar());
    } catch (error) {
      dispatch(hideLoadingBar());
      toast.error(error.response.data.message);
    }
  };

  useEffect(() => {
    fetchGroupMembers();
  }, []);

  const handleSelectMember = (memberDetails) => {
    const index = selectedMemberList.findIndex(
      (member) => member.id === memberDetails.id
    );

    let updatedList = [...selectedMemberList];

    if (index === -1) {
      updatedList.push(memberDetails);
      setSelectedMemberList(updatedList);
    } else {
      updatedList.splice(index, 1);
      setSelectedMemberList(updatedList);
    }
  };

  const isMemberSelected = (memberDetails) => {
    const index = selectedMemberList.findIndex(
      (member) => member.id === memberDetails.id
    );

    return index !== -1;
  };

  const updateInviteStatus = async (status) => {
    const formData = {
      idList: [],
      note: noteForm.note,
    };

    selectedMemberList.forEach((listItem) => {
      formData.idList.push(listItem.id);
    });
    dispatch(showLoadingBar());
    try {
      await updateDependentsEventStatus(eventData.id, status, formData);
      fetchGroupMembers();
      dispatch(hideLoadingBar());
      setSelectedMemberList([]);
      setNoteForm({
        ...noteForm,
        note: "",
      });
      toast.success(
        "Invite Status for Group members has been updated successfully."
      );
    } catch (error) {
      dispatch(hideLoadingBar());
      toast.error(error.response.data.message);
    }
  };

  const handleEventNavigate = () => {
    navigate(`/guest/event/${eventData.id}`);
  };

  return (
    <>
      <div className="rsvp-event-details-container">
        <div className="rsvp-event-icon-container">
          <img src={Sangeeth} alt="Event" className="rsvp-event-icon" />
        </div>
        <div className="rsvp-event-heading-container">
          <span className="rsvp-event-name">{eventData.name}</span>
          <span className="rsvp-event-date">
            {formatDate(eventData.startDate)}
          </span>
        </div>

        <div className="rsvp-event-time-details-container">
          <span className="rsvp-event-time-details">
            {getDefaultTimeFormatter(eventData?.startDate)}
          </span>
          <span>-</span>
          <span className="rsvp-event-time-details">
            {getDefaultTimeFormatter(eventData?.endDate)}
          </span>
        </div>

        <div
          className="rsvp-event-details-button"
          onClick={handleEventNavigate}
        >
          Event Details
        </div>

        <div className="rsvp-event-response-container">
          <span className="rsvp-event-response-text">Kindly Respond</span>
          <span className="rsvp-event-respond-by-date">
            By {formatDate(eventData.rsvpEndDate)}
          </span>
        </div>

        <div className="rsvp-event-looking-forward-text">
          We look forward to celebrating with you!
        </div>

        {groupMembers.length ? (
          <>
            <div className="group-members-root">
              <span className="group-members-heading">
                Please update the RSVP status for Group Members
              </span>
              <div className="group-members-container">
                {groupMembers.map((memberDetails) => {
                  return (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isMemberSelected(memberDetails)}
                          onChange={() => handleSelectMember(memberDetails)}
                          checkedIcon={
                            <CheckBoxRoundedIcon className="checkbox-selected" />
                          }
                        />
                      }
                      label={
                        <>
                          {memberDetails.name}&nbsp;(
                          {memberDetails.sharingStatusType.name})
                        </>
                      }
                    />
                  );
                })}
              </div>

              <div className="w-100 my-3">
                <FormControl fullWidth size="small">
                  <TextField
                    id="note"
                    name="note"
                    className="form-note-input"
                    size="small"
                    placeholder="Kindly offer your feedback or Share your reasons for declining here..."
                    value={noteForm.note}
                    onChange={handleFormDataChange}
                    multiline
                    rows={2}
                  />
                </FormControl>
              </div>

              <div className="group-invite-actions-container">
                <Button
                  variant="outlined"
                  className="secondary-button"
                  onClick={() => {
                    updateInviteStatus(RSVP_EVENT_STATUS.DECLINED);
                  }}
                  disabled={!selectedMemberList.length}
                >
                  Decline
                </Button>

                <Button
                  variant="outlined"
                  className="button"
                  onClick={() => {
                    updateInviteStatus(RSVP_EVENT_STATUS.ACCEPTED);
                  }}
                  disabled={!selectedMemberList.length}
                >
                  Accept
                </Button>
              </div>
            </div>
          </>
        ) : (
          <span className="response-received-text">
            Received responses for all group Members
          </span>
        )}
      </div>
    </>
  );
};

export default GroupEventDetails;
