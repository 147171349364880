import React from 'react';
import "./chatbot.css";

function Chatbot() {
  return (
    <div className="Chatbot">
      <header className="App-header">
        
        <div id="chatbot-placeholder">
          <df-messenger
            project-id="mybot-424618"
            agent-id="24f8da03-e1a6-4e6b-aef4-434605b60438"
            language-code="en"
            max-query-length="-1">
            <df-messenger-chat-bubble chat-title="Mishnu Bot"></df-messenger-chat-bubble>
          </df-messenger>
        </div>
      </header>
      <style jsx>{`
        df-messenger {
          z-index: 999;
          position: fixed;
          --df-messenger-font-color: #000000;
          --df-messenger-font-family: 'Google Sans', sans-serif;
          --df-messenger-chat-background-color: #004C54;
          --df-messenger-chat-background: #004C54;
          --df-messenger-message-user-background-color: #ACC0C6;
          --df-messenger-message-bot-background-color: #00FF00;
          bottom: 16px;
          right: 16px;
        }
      `}</style>
    </div>
  );
}



export default Chatbot;