import { ACTION_TYPE_ENUMS } from "../actions/actionTypes";

const initialState = {
  userInfo: null,
};

const userInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPE_ENUMS.UPDATE_USER_INFO:
      return {
        ...state,
        userInfo: action.payload,
      };
    default:
      return state;
  }
};

export default userInfoReducer;
