import "./index.css";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { dataFormatter } from "../../../services/formHelperService";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  hideLoadingBar,
  openSidePanel,
  refreshData,
  showLoadingBar,
  updateSelectedEvent,
  updateSelectedGuestList,
} from "../../../actions/actions";
import { MORE_ACTION_TYPE, TABLE_TYPE } from "../../../utils/configs";
import { useNavigate } from "react-router-dom";
import {
  assignGroupLeader,
  cancelEventById,
  sendEventRemainder,
  sendThankyouEmail,
} from "../../../services/adminService";
import { toast } from "react-toastify";

const CustomTable = ({ tableConfig, tableData }) => {
  const dispatch = useDispatch();
  const [moreActionsElement, setMoreActionsElement] = useState(null);
  const isMoreActionsMenuOpen = Boolean(moreActionsElement);
  const [selectedRow, setSelectedRow] = useState(null);
  const navigate = useNavigate();
  const selectedDataReducer = useSelector((state) => state.selectedDataReducer);
  const selectedGuestList = selectedDataReducer.selectedGuestList;

  const handleOpenMenu = (event, rowData) => {
    setMoreActionsElement(event.currentTarget);
    setSelectedRow(rowData);
  };

  const handleActionType = (actionType) => {
    switch (actionType) {
      case MORE_ACTION_TYPE.EVENT_GUEST_INVITE:
        dispatch(updateSelectedEvent(selectedRow));
        navigate(`/event-invite/${selectedRow.id}`);
        break;
      case MORE_ACTION_TYPE.GUEST_INVITE:
        dispatch(updateSelectedGuestList([selectedRow]));
        dispatch(openSidePanel(actionType, selectedRow));
        break;
      case MORE_ACTION_TYPE.EVENT_REMAINDER:
        sendRemainderEmail();
        break;
      case MORE_ACTION_TYPE.CANCEL_EVENT:
        cancelEvent();
        break;
      case MORE_ACTION_TYPE.SEND_THANK_YOU:
        sendThankYouMail();
        break;
      default:
        dispatch(openSidePanel(actionType, selectedRow));
        break;
    }

    handleCloseMenu();
  };

  const handleCloseMenu = () => {
    setMoreActionsElement(null);
    setSelectedRow(null);
  };

  const isRowDataSelected = (rowData) => {
    if (tableConfig.tableType === TABLE_TYPE.GUEST) {
      const index = selectedGuestList.findIndex(
        (listItem) => listItem.id === rowData.id
      );
      return index !== -1;
    }

    return false;
  };

  const handleRowSelect = (rowData) => {
    const index = selectedGuestList.findIndex(
      (listItem) => listItem.id === rowData.id
    );

    const updatedSelectedList = [...selectedGuestList];

    if (index !== -1) {
      updatedSelectedList.splice(index, 1);
    } else {
      updatedSelectedList.push(rowData);
    }

    if (tableConfig.tableType === TABLE_TYPE.GUEST) {
      dispatch(updateSelectedGuestList(updatedSelectedList));
    }
  };

  const handleMultiSelect = (event) => {
    let updatedSelectedList = [];

    if (event.target.checked) {
      updatedSelectedList = [...tableData];
    } else {
      updatedSelectedList = [];
    }

    dispatch(updateSelectedGuestList(updatedSelectedList));
  };

  const isAllSelected = () => {
    return selectedGuestList.length === tableData.length;
  };

  const getNumberOfColumns = () => {
    let numberOfColumns = tableConfig.columnConfigs.length;
    if (tableConfig.isCheckBox) {
      numberOfColumns++;
    }

    if (tableConfig.isActions) {
      numberOfColumns++;
    }

    return numberOfColumns;
  };

  const showGroupLeaderOption = () => {
    if (
      tableConfig.tableType === TABLE_TYPE.GUEST &&
      selectedRow?.groupId &&
      !selectedRow?.groupLeader
    ) {
      return (
        <MenuItem onClick={assignGroupLeaderRole}>Assign Group Leader</MenuItem>
      );
    }
  };

  const assignGroupLeaderRole = async () => {
    dispatch(showLoadingBar());
    try {
      await assignGroupLeader(selectedRow.groupId, selectedRow.id);
      handleCloseMenu();
      dispatch(refreshData());
      dispatch(hideLoadingBar());
    } catch (error) {
      dispatch(hideLoadingBar());
      toast.error(error.response.data.message);
    }
  };

  const cancelEvent = async () => {
    dispatch(showLoadingBar());
    try {
      await cancelEventById(selectedRow.id);
      dispatch(refreshData());
      dispatch(hideLoadingBar());
      toast.success("Event has been cancelled successfully.");
    } catch (error) {
      dispatch(hideLoadingBar());
      toast.error(error.response.data.message);
    }
  };

  const sendRemainderEmail = async () => {
    dispatch(showLoadingBar());
    try {
      await sendEventRemainder(selectedRow.id);
      handleCloseMenu();
      dispatch(hideLoadingBar());
      toast.success("Event remainder has been sent successfully.");
    } catch (error) {
      dispatch(hideLoadingBar());
      toast.error(error.response.data.message);
    }
  };

  const sendThankYouMail = async () => {
    dispatch(showLoadingBar());
    try {
      await sendThankyouEmail(selectedRow.id);
      handleCloseMenu();
      dispatch(hideLoadingBar());
      toast.success("Thank You mails have been sent successfully.");
    } catch (error) {
      dispatch(hideLoadingBar());
      toast.error(error.response.data.message);
    }
  };

  return (
    <>
      <Menu
        id="moreActionsMenu"
        anchorEl={moreActionsElement}
        open={isMoreActionsMenuOpen}
        onClose={handleCloseMenu}
      >
        {tableConfig.actionItems.map((actionItem) => (
          <MenuItem
            key={actionItem.type}
            onClick={() => {
              handleActionType(actionItem.type);
            }}
          >
            {actionItem.label}
          </MenuItem>
        ))}
        {showGroupLeaderOption()}
      </Menu>
      <table className="dashboard-table-container">
        <thead>
          <tr className="table-header">
            {tableConfig.isCheckBox ? (
              <th align="center">
                <input
                  type="checkbox"
                  checked={isAllSelected()}
                  onChange={handleMultiSelect}
                />
              </th>
            ) : null}

            {tableConfig.columnConfigs.map((columnConfig) => {
              return (
                <th
                  key={`header_${columnConfig.fieldKey}`}
                  align={columnConfig.align}
                >
                  {columnConfig.label}
                </th>
              );
            })}

            {tableConfig.isActions ? <th></th> : null}
          </tr>
        </thead>

        <tbody>
          {tableData?.length ? (
            <>
              {tableData.map((dataItem) => {
                return (
                  <tr
                    className="table-data-row"
                    key={`${tableConfig.tableType}_${dataItem.id}`}
                  >
                    {tableConfig.isCheckBox ? (
                      <td width="5%" align="center">
                        <input
                          type="checkbox"
                          checked={isRowDataSelected(dataItem)}
                          onChange={() => handleRowSelect(dataItem)}
                        />
                      </td>
                    ) : null}

                    {tableConfig.columnConfigs.map((columnConfig) => {
                      return (
                        <td
                          key={`dataRow_${columnConfig.fieldKey}`}
                          width={columnConfig.width}
                          align={columnConfig.align}
                        >
                          {dataFormatter(
                            columnConfig.fieldType,
                            columnConfig.fieldKey,
                            dataItem
                          )}
                        </td>
                      );
                    })}

                    {tableConfig.isActions ? (
                      <td width="5%">
                        <IconButton
                          onClick={(event) => handleOpenMenu(event, dataItem)}
                          id="moreActionsButton"
                        >
                          <MoreHorizIcon />
                        </IconButton>
                      </td>
                    ) : null}
                  </tr>
                );
              })}
            </>
          ) : (
            <tr className="table-data-row">
              <td colSpan={getNumberOfColumns()}>No Data Available</td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
};

export default CustomTable;
