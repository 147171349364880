import { ACTION_TYPE_ENUMS } from "../actions/actionTypes";

const initialState = {
  selectedContact: null,
};

const messagingReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPE_ENUMS.UPDATE_SELECTED_CONTACT:
      return {
        ...state,
        selectedContact: action.payload,
      };
    default:
      return state;
  }
};

export default messagingReducer;
