import {
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  TextField,
} from "@mui/material";
import "./index.css";
import Submit from "../../assets/Submit.svg";
import { CONSENT_MESSAGES } from "../../utils/constants";
import { getOtp, verifyOtp } from "../../services/individualService";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useRef, useState } from "react";
import CheckBoxRoundedIcon from "@mui/icons-material/CheckBoxRounded";
import DriveFileRenameOutlineRoundedIcon from "@mui/icons-material/DriveFileRenameOutlineRounded";

const VerifyRsvp = () => {
  const [showOtpFields, setShowOtpFields] = useState(false);
  const [userIdentity, setUserIdentity] = useState("");
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [isConsentChecked, setIsConsentChecked] = useState(false);

  const otpBoxReference = useRef([]);
  const navigate = useNavigate();
  const numberOfDigits = 6;

  const getLoginOtp = async () => {
    setDisableSubmit(true);
    try {
      await getOtp(userIdentity);
      setShowOtpFields(true);
      setDisableSubmit(false);
    } catch (error) {
      setDisableSubmit(false);
      toast.error(error.response.data.message);
    }
  };

  const verifyLogin = async () => {
    const formData = {
      identity: userIdentity,
      otp: otp.join(""),
    };
    try {
      const response = await verifyOtp(formData);
      // store the token
      sessionStorage.setItem("userToken", response.data);
      navigate("/guest/rsvp-event-list");
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const handleBackspaceAndEnter = (e, index, boxReference) => {
    if (e.key === "Backspace" && !e.target.value && index > 0) {
      boxReference.current[index - 1].focus();
    }
    if (e.key === "Enter" && e.target.value && index < numberOfDigits - 1) {
      boxReference.current[index + 1].focus();
    }
  };

  const handleChangeOtp = (value, index, otpArray, setArray, boxReference) => {
    let newArr = [...otpArray];
    newArr[index] = value;
    setArray(newArr);

    if (value && index < numberOfDigits - 1) {
      boxReference.current[index + 1].focus();
    }
  };

  const resendOtp = () => {
    setOtp(new Array(6).fill(""));
    getLoginOtp();
  };

  const getOtpButtonDisabled = () => {
    return disableSubmit || !isConsentChecked;
  };

  const reEditIdentity = () => {
    setOtp(new Array(6).fill(""));
    setShowOtpFields(false);
  };

  return (
    <>
      <div className="verify-rsvp-container">
        <div className="verify-rsvp-heading">Mouna Allani & Vishnu Razdan</div>
        <div className="verify-rsvp-sub-heading">
          <span>
            We are so excited to celebrate our big day with our loved ones!
          </span>
        </div>
        {!showOtpFields ? (
          <div className="verify-rsvp-form-container">
            <div className="verify-rsvp-sub-heading">
              <b>Please enter your phone number/email address below.</b>
              <span>
                Upon doing so, you will receive a one time passcode that you can
                use to RSVP.
              </span>
            </div>
            <div className="verify-rsvp-form-control-row">
              <TextField
                placeholder="Phone / Email"
                sx={{
                  "& .MuiInputBase-input": {
                    border: "1px solid #000000 !important",
                    borderRadius: "50px !important",
                    width: "260px !important",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "unset !important",
                  },
                }}
                value={userIdentity}
                onChange={(event) => {
                  setUserIdentity(event.target.value);
                }}
              />
              <IconButton
                className="get-otp-button"
                disabled={getOtpButtonDisabled()}
                onClick={getLoginOtp}
              >
                <img
                  src={Submit}
                  alt="Submit"
                  className="verify-rsvp-form-submit-icon"
                />
              </IconButton>
            </div>
            <div className="verify-rsvp-form-control-row consent-control">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isConsentChecked}
                    onChange={(event) => {
                      setIsConsentChecked(event.target.checked);
                    }}
                    checkedIcon={
                      <CheckBoxRoundedIcon className="checkbox-selected" />
                    }
                  />
                }
                label={CONSENT_MESSAGES.phone}
              />
            </div>
          </div>
        ) : (
          <div className="otp-form-container">
            <div className="rsvp-enter-details-container">
              <span>{userIdentity}</span>
              <IconButton onClick={reEditIdentity}>
                <DriveFileRenameOutlineRoundedIcon />
              </IconButton>
            </div>
            <div className="verify-rsvp-sub-heading">Please enter OTP here</div>
            <div className={"otp-fields-container"}>
              {otp.map((digit, index) => (
                <input
                  key={`otp_${index}`}
                  value={digit}
                  placeholder="-"
                  maxLength={1}
                  onChange={(e) =>
                    handleChangeOtp(
                      e.target.value,
                      index,
                      otp,
                      setOtp,
                      otpBoxReference
                    )
                  }
                  onKeyUp={(e) =>
                    handleBackspaceAndEnter(e, index, otpBoxReference)
                  }
                  ref={(reference) =>
                    (otpBoxReference.current[index] = reference)
                  }
                  className={"otp-field-input"}
                />
              ))}
            </div>
            <div className="otp-form-actions-container">
              <Button className="secondary-button" onClick={resendOtp}>
                Resend OTP
              </Button>
              <Button className="button" onClick={verifyLogin}>
                Login
              </Button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default VerifyRsvp;
