import dayjs from "dayjs";
import "../components/guestStyles.css";
import { useNavigate } from "react-router-dom";
import { RSVP_EVENT_STATUS } from "../../../utils/constants";
import { useDispatch } from "react-redux";
import { hideLoadingBar, showLoadingBar } from "../../../actions/actions";
import { updatedEventStatus } from "../../../services/guestService";
import { toast } from "react-toastify";
import { FormControl, TextField } from "@mui/material";
import { useState } from "react";

const EventCard = ({ eventData, handleRefresh }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const eventDetails = eventData.webSubEvent;
  const statusDetails = eventData.sharingStatusType;

  const formatDate = (date) => {
    return dayjs(date).format("DD MMM, YYYY  h:mm A");
  };

  const [noteForm, setNoteForm] = useState({
    note: "",
  });

  const handleFormDataChange = (event) => {
    setNoteForm({
      ...noteForm,
      [event.target.name]: event.target.value,
    });
  };

  const handleEventNavigate = () => {
    navigate(`/guest/event/${eventDetails.id}`);
  };

  const updateInviteStatus = async (status) => {
    dispatch(showLoadingBar());
    try {
      const formData = {
        note: noteForm.note,
      };
      await updatedEventStatus(eventDetails.id, status, formData);
      dispatch(hideLoadingBar());
      handleRefresh();
      setNoteForm({
        ...noteForm,
        note: "",
      });
    } catch (error) {
      dispatch(hideLoadingBar());
      toast.error(error.response.data.message);
    }
  };

  const getActionButtons = () => {
    switch (statusDetails.typeEnum) {
      case RSVP_EVENT_STATUS.ACCEPTED:
        return (
          <>
            <span className="event-card-action-button-highlighted">
              Accepted
            </span>
            <div
              className="event-card-action-button-link-container"
              onClick={() => updateInviteStatus(RSVP_EVENT_STATUS.DECLINED)}
            >
              <span className="event-card-action-button-change-text">
                Change To
              </span>
              <span className="event-card-action-button-link">Decline</span>
            </div>
          </>
        );
      case RSVP_EVENT_STATUS.DECLINED:
        return (
          <>
            <>
              <div
                className="event-card-action-button-link-container"
                onClick={() => updateInviteStatus(RSVP_EVENT_STATUS.ACCEPTED)}
              >
                <span className="event-card-action-button-change-text">
                  Change To
                </span>
                <span className="event-card-action-button-link">Accept</span>
              </div>
              <span className="event-card-action-button-highlighted">
                Declined
              </span>
            </>
            ;
          </>
        );
      default:
        return (
          <>
            <span
              className="event-card-action-button"
              onClick={() => updateInviteStatus(RSVP_EVENT_STATUS.ACCEPTED)}
            >
              Accept
            </span>
            <span
              className="event-card-action-button"
              onClick={() => updateInviteStatus(RSVP_EVENT_STATUS.DECLINED)}
            >
              Decline
            </span>
          </>
        );
    }
  };

  return (
    <div className="event-card-root">
      <div className="event-card-container">
        <div
          className="event-card-details-container"
          onClick={handleEventNavigate}
        >
          <div className="event-card-name">{eventDetails.name}</div>
          <div className="event-card-details">
            {formatDate(eventDetails.startDate)}
          </div>
          <div className="event-card-details-text">Event Details</div>
        </div>
        <div className="event-card-actions-container">
          <span className="event-card-address">{eventDetails.location}</span>

          {/* <div className="event-note-form-container">
            <FormControl fullWidth size="small">
              <TextField
                id="note"
                name="note"
                className="form-note-input"
                size="small"
                placeholder="Kindly offer your feedback or Share your reasons for declining here..."
                value={noteForm.note}
                onChange={handleFormDataChange}
                multiline
                rows={2}
              />
            </FormControl>
          </div>

          <div className="event-card-actions">{getActionButtons()}</div> */}
        </div>
      </div>
      {/* <div className="event-card-respond-by-text">
        * Respond By {formatDate(eventDetails.rsvpEndDate)}
      </div> */}
    </div>
  );
};

export default EventCard;
