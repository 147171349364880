import { useDispatch } from "react-redux";
import {
  getFormControlText,
  isFormControlError,
} from "../../../services/formHelperService";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  FORM_VALIDATION_MESSAGES,
  MESSAGE_TYPE_ENUM,
  RECEIPIENT_LIST_TYPE_ENUM,
} from "../../../utils/constants";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { hideLoadingBar, showLoadingBar } from "../../../actions/actions";
import { toast } from "react-toastify";
import { sendMessageFromEvent } from "../../../services/adminService";

const MessageForm = ({ closePanel, eventDetails }) => {
  const dispatch = useDispatch();

  const initialValues = {
    eventId: eventDetails.id,
    messageType: MESSAGE_TYPE_ENUM.BROADCAST,
    receipientListType: RECEIPIENT_LIST_TYPE_ENUM.ACCEPTED,
    message: "",
  };

  const handleFormSubmit = (values) => {
    const messageRequestBody = {
      subEventId: messageForm.values.eventId,
      isBroadcastMessage: values.messageType === MESSAGE_TYPE_ENUM.BROADCAST,
      recipientListType: values.receipientListType,
      content: values.message,
    };
    sendConversationMessage(messageRequestBody);
  };

  const sendConversationMessage = async (requestBody) => {
    dispatch(showLoadingBar());
    try {
      await sendMessageFromEvent(requestBody);
      dispatch(hideLoadingBar());
      toast.success("Message sent successfully.");
      closePanel();
    } catch (error) {
      dispatch(hideLoadingBar());
      toast.error(error.response?.data?.message);
    }
  };

  const messageForm = useFormik({
    initialValues,
    validationSchema: Yup.object({
      messageType: Yup.string().required(
        FORM_VALIDATION_MESSAGES.MESSAGE_TYPE_REQUIRED
      ),
      receipientListType: Yup.string().required(
        FORM_VALIDATION_MESSAGES.RECEIPIENTS_REQUIRED
      ),
      message: Yup.string().required(FORM_VALIDATION_MESSAGES.MESSAGE_REQUIRED),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      handleFormSubmit(values);
    },
  });

  return (
    <>
      <div className="side-panel-root">
        <form className="form-container">
          <div className="event-name-container">
            <span className="event-name">Event: {eventDetails.name}</span>
          </div>
          <FormControl fullWidth size="small">
            <span className="form-control-label">Type of Message *</span>
            <RadioGroup
              id="messageType"
              name="messageType"
              value={messageForm.values.messageType}
              onChange={messageForm.handleChange}
              onBlur={messageForm.handleBlur}
              row
            >
              <FormControlLabel
                value={MESSAGE_TYPE_ENUM.BROADCAST}
                control={
                  <Radio
                    sx={{
                      "&.Mui-checked": {
                        color: "#000",
                      },
                    }}
                  />
                }
                label="Broadcast"
              />
              <FormControlLabel
                value={MESSAGE_TYPE_ENUM.TWO_WAY}
                control={
                  <Radio
                    sx={{
                      "&.Mui-checked": {
                        color: "#000",
                      },
                    }}
                  />
                }
                label="Two Way"
              />
            </RadioGroup>
            {isFormControlError(messageForm, "messageType") && (
              <FormHelperText className="form-control-error">
                {getFormControlText(messageForm, "messageType")}
              </FormHelperText>
            )}
          </FormControl>

          <FormControl fullWidth size="small">
            <span className="form-control-label">Receipients *</span>
            <RadioGroup
              id="receipientListType"
              name="receipientListType"
              value={messageForm.values.receipientListType}
              onChange={messageForm.handleChange}
              onBlur={messageForm.handleBlur}
              row
            >
              <FormControlLabel
                value={RECEIPIENT_LIST_TYPE_ENUM.ALL}
                control={
                  <Radio
                    sx={{
                      "&.Mui-checked": {
                        color: "#000",
                      },
                    }}
                  />
                }
                label="To All"
              />
              <FormControlLabel
                value={RECEIPIENT_LIST_TYPE_ENUM.ACCEPTED}
                control={
                  <Radio
                    sx={{
                      "&.Mui-checked": {
                        color: "#000",
                      },
                    }}
                  />
                }
                label="To Accepted"
              />
            </RadioGroup>
            {isFormControlError(messageForm, "receipientListType") && (
              <FormHelperText className="form-control-error">
                {getFormControlText(messageForm, "receipientListType")}
              </FormHelperText>
            )}
          </FormControl>

          <FormControl fullWidth size="small">
            <span className="form-control-label">Message *</span>
            <TextField
              id="message"
              name="message"
              className="form-input-control"
              size="small"
              placeholder="Type your message...."
              value={messageForm.values.message}
              onChange={messageForm.handleChange}
              onBlur={messageForm.handleBlur}
              multiline
              rows={3}
            />
            {isFormControlError(messageForm, "message") && (
              <FormHelperText className="form-control-error">
                {getFormControlText(messageForm, "message")}
              </FormHelperText>
            )}
          </FormControl>
        </form>

        <div className="form-actions-container">
          <Button
            variant="contained"
            className="cancel-button"
            onClick={closePanel}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            className="submit-button"
            onClick={messageForm.submitForm}
          >
            Send
          </Button>
        </div>
      </div>
    </>
  );
};

export default MessageForm;
