import { Outlet } from "react-router-dom";
import Header from "../Header";
import Footer from "../Footer";
import { LinearProgress } from "@mui/material";
import { useSelector } from "react-redux";

const RouteLayout = () => {
  const refreshReducer = useSelector((state) => state.refreshReducer);

  const showLoadingBar = refreshReducer.loading;

  return (
    <>
      <Header />
      {showLoadingBar ? <LinearProgress color="inherit" /> : null}
      <div className="outlet-container">
      <Outlet />
      </div>
      <Footer />
    </>
  );
};

export default RouteLayout;
