import "./header.css";
import Person from "../../assets/Person.svg";
import useMediaQuery from "@mui/material/useMediaQuery";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import { Button, IconButton, Menu, MenuItem } from "@mui/material";
import SlidingPane from "react-sliding-pane";
import { useEffect, useState } from "react";
import Sidebar from "../Guest/components/Sidebar";
import {
  getEventTypeList,
  getLoggedInUser,
  getPriorityTypeList,
} from "../../services/individualService";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  updateEventTypes,
  updatePriorityTypes,
  updateSelectedContact,
  updateUserInfo,
} from "../../actions/actions";
import { useLocation, useNavigate } from "react-router-dom";
import { USER_ROLES } from "../../utils/constants";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";

const Header = (type) => {
  const isSmallScreen = useMediaQuery("(max-width:1024px)");
  const [openPanel, setOpenPanel] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const userInfoReducer = useSelector((state) => state.userInfoReducer);

  const [moreActionsElement, setMoreActionsElement] = useState(null);
  const isMoreActionsMenuOpen = Boolean(moreActionsElement);

  const userInfo = userInfoReducer?.userInfo;

  const isGroupLeader = () => {
    return userInfo?.groupLeader;
  };

  const getLoggedInUserDetails = async () => {
    try {
      const response = await getLoggedInUser();
      const userDetails = response?.data;
      // update state
      dispatch(updateUserInfo(userDetails));
      startNavigaton(userDetails);
    } catch (error) {
      navigate("/login");
      // toast.error("Please Login again");
    }
  };

  const startNavigaton = (userDetails) => {
    if (location.pathname === "/welcome") {
      const roleType = userDetails.roleType.typeEnum;
      if (roleType === USER_ROLES.guest) {
        navigate("/guest");
      } else {
        navigate("/");
      }
    } else {
      navigate(location.pathname);
    }
  };
  const toggleSideBar = () => {
    setOpenPanel((openPanel) => !openPanel);
  };

  const fetchMasterData = () => {
    fetchPriorityTypes();
    fetchEventTypes();
  };

  const fetchPriorityTypes = async () => {
    try {
      const response = await getPriorityTypeList();
      const priorityTypes = response?.data;
      dispatch(updatePriorityTypes(priorityTypes));
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const fetchEventTypes = async () => {
    try {
      const response = await getEventTypeList();
      const eventTypes = response?.data;
      dispatch(updateEventTypes(eventTypes));
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  useEffect(() => {
    getLoggedInUserDetails();
  }, []);

  useEffect(() => {
    const roleType = userInfo?.roleType?.typeEnum;
    if (roleType === USER_ROLES.admin) {
      fetchMasterData();
    }
  }, [userInfo]);

  const handleToggleMenu = (event, rowData) => {
    setMoreActionsElement(event?.currentTarget);
  };

  const handleLogOut = () => {
    sessionStorage.clear();
    handleToggleMenu();
    navigate("/login");
  };

  const handleRSVPClick = (event) => {
    // setAnchorEl(event.currentTarget);
    if (isGroupLeader()) {
      navigate("/guest/group-invite");
    } else {
      navigate("/guest/rsvp-event-list");
    }
    clearSelectedContact();
  };

  const clearSelectedContact = () => {
    dispatch(updateSelectedContact(null));
  };

  return (
    <>
      <Menu
        id="moreActionsMenu"
        anchorEl={moreActionsElement}
        open={isMoreActionsMenuOpen}
        onClose={() => handleToggleMenu()}
      >
        <MenuItem
          tabIndex={-1}
          className="profile-menu-items"
          onClick={() => {
            navigate("/profile");
            clearSelectedContact();
            handleToggleMenu();
          }}
        >
          My Profile
        </MenuItem>
        <MenuItem tabIndex={-1} onClick={handleLogOut}>
          Logout
        </MenuItem>
      </Menu>
      <div className="header-container">
        {isSmallScreen ? (
          <div className="sidebar-container">
            <IconButton onClick={toggleSideBar}>
              <MenuRoundedIcon />
            </IconButton>
            <SlidingPane
              className="sidebar-pane"
              from={"left"}
              isOpen={openPanel}
              onRequestClose={toggleSideBar}
              title={userInfo?.name}
            >
              <Sidebar closeSidebar={toggleSideBar} />
            </SlidingPane>
          </div>
        ) : null}

        <div
          className="header-title-container"
          onClick={() => {
            navigate(
              userInfo?.roleType?.typeEnum === USER_ROLES.admin ? "/admin" : "/"
            );
            clearSelectedContact();
          }}
        >
          <span>Mouna</span>
          <span className="highlight-text">&nbsp;&&nbsp;</span>
          <span>Vishnu</span>
        </div>

        <div className="logged-in-user-details-container">
          <img src={Person} alt="avatar" className="avatar" />
          <div className="logged-in-user-details">
            <span className="welcome-text">Welcome</span>
            <span className="logged-in-user-name" onClick={handleToggleMenu}>
              {userInfo?.name}&nbsp;
              <ArrowDropDownRoundedIcon />
            </span>
          </div>
        </div>
      </div>

      {!isSmallScreen && (
        <div className="menu-quick-links-container">
          <Button
            variant="text"
            onClick={() => {
              navigate("/");
              clearSelectedContact();
            }}
          >
            Home
          </Button>

          <Button
            variant="text"
            onClick={() => {
              navigate("/messages");
              clearSelectedContact();
            }}
          >
            Messages
          </Button>

          {userInfo?.roleType?.typeEnum !== USER_ROLES.admin ? (
            <>
              <Button
                variant="text"
                onClick={() => {
                  navigate("/guest/event-list");
                  clearSelectedContact();
                }}
              >
                Events
              </Button>
              <Button
                variant="text"
                // endIcon={<KeyboardArrowDownIcon />}
                onClick={handleRSVPClick}
              >
                RSVP
              </Button>
              {/* <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleRSVPMenuItemClick}
              >
                {isGroupLeader() ? (
                  <MenuItem
                    onClick={() => {
                      navigate("/guest/group-invite");
                      handleRSVPMenuItemClick();
                    }}
                  >
                    My Group Invites
                  </MenuItem>
                ) : (
                  <MenuItem
                    onClick={() => {
                      navigate("/guest/rsvp-event-list");
                      handleRSVPMenuItemClick();
                    }}
                  >
                    My Invites
                  </MenuItem>
                )}
              </Menu> */}
            </>
          ) : null}
          <Button
            variant="text"
            onClick={() => {
              navigate("/travel-info");
              clearSelectedContact();
            }}
          >
            Travel
          </Button>
          <Button
            variant="text"
            onClick={() => {
              navigate("/gallery");
              clearSelectedContact();
            }}
          >
            Gallery
          </Button>
          <Button
            variant="text"
            onClick={() => {
              navigate("/faq");
              clearSelectedContact();
            }}
          >
            FAQ
          </Button>
          <Button
            variant="text"
            onClick={() => {
              navigate("/registry");
              clearSelectedContact();
            }}
          >
            Gift Registry
          </Button>
          {userInfo?.roleType?.typeEnum === USER_ROLES.admin ? (
            <Button
              variant="text"
              onClick={() => {
                navigate("/admin");
                clearSelectedContact();
              }}
            >
              Admin Dashboard
            </Button>
          ) : null}
        </div>
      )}
    </>
  );
};

export default Header;
