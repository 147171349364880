import { useFormik } from "formik";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import * as Yup from "yup";
import { FORM_VALIDATION_MESSAGES } from "../../../utils/constants";
import {
  getFormControlText,
  getFormControlValue,
  isFormControlError,
} from "../../../services/formHelperService";
import { useDispatch, useSelector } from "react-redux";
import { saveGuestDetails } from "../../../services/adminService";
import { toast } from "react-toastify";
import {
  hideLoadingBar,
  refreshData,
  showLoadingBar,
} from "../../../actions/actions";
import "../index.css";

const AddGuestForm = ({ closePanel, guestDetails }) => {
  const dispatch = useDispatch();
  const masterData = useSelector((state) => state.masterDataReducer);

  const priorityTypes = masterData.priorityTypes;
  const groupList = masterData.groupList;

  const getFormattedPhoneNumber = (phone) => {
    return phone ? phone.split("+1").join("") : "";
  };

  const initialValues = {
    id: getFormControlValue(guestDetails?.id),
    name: getFormControlValue(guestDetails?.name),
    ageGroupTypeEnum: getFormControlValue(guestDetails?.ageGroupType.typeEnum),
    gender: getFormControlValue(guestDetails?.gender),
    phone: getFormattedPhoneNumber(guestDetails?.phone),
    email: getFormControlValue(guestDetails?.email),
    marriageGroupTypeEnum: getFormControlValue(
      guestDetails?.marriageGroupType.typeEnum
    ),
    groupId: getFormControlValue(guestDetails?.groupId),
    priorityTypeEnum: getFormControlValue(guestDetails?.priorityType.typeEnum),
  };

  const handleFormSubmit = (values) => {
    saveGuest(values);
  };

  const saveGuest = async (eventData) => {
    const formData = {
      ...eventData,
    };
    if (eventData.phone) {
      formData.phone = `+1${eventData.phone}`;
    }
    dispatch(showLoadingBar());
    try {
      await saveGuestDetails(formData);
      dispatch(refreshData());
      closePanel();
      dispatch(hideLoadingBar());
      toast.success("Guest Details have been saved successfully.");
    } catch (error) {
      dispatch(hideLoadingBar());
      toast.error(error.response.data.message);
    }
  };

  const guestDetailsForm = useFormik({
    initialValues,
    validationSchema: Yup.object({
      name: Yup.string().required(FORM_VALIDATION_MESSAGES.FULLNAME_REQUIRED),
      ageGroupTypeEnum: Yup.string().required(
        FORM_VALIDATION_MESSAGES.AGE_GROUP_REQUIRED
      ),
      gender: Yup.string().required(FORM_VALIDATION_MESSAGES.GENDER_REQUIRED),
      phone: Yup.string(),
      email: Yup.string().email(FORM_VALIDATION_MESSAGES.EMAIL_VALIDATION),
      marriageGroupTypeEnum: Yup.string().required(
        FORM_VALIDATION_MESSAGES.GUEST_TYPE_REQUIRED
      ),
      priorityTypeEnum: Yup.string().required(
        FORM_VALIDATION_MESSAGES.PRIORITY_REQUIRED
      ),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      handleFormSubmit(values);
    },
  });

  return (
    <div className="side-panel-root">
      <form className="form-container">
        <FormControl fullWidth size="small">
        <span className="form-control-label">Guest Name *</span>
          <TextField
            id="name"
            name="name"
            className="form-input-control"
            size="small"
            placeholder="Full Name"
            value={guestDetailsForm.values.name}
            onChange={guestDetailsForm.handleChange}
            onBlur={guestDetailsForm.handleBlur}
          />
          {isFormControlError(guestDetailsForm, "name") && (
            <FormHelperText className="form-control-error">
              {getFormControlText(guestDetailsForm, "name")}
            </FormHelperText>
          )}
        </FormControl>

        <FormControl fullWidth size="small">
        <span className="form-control-label">Gender *</span>
          <Select
            id="gender"
            name="gender"
            value={guestDetailsForm.values.gender}
            placeholder="Gender"
            displayEmpty
            className="form-select-control"
            onChange={guestDetailsForm.handleChange}
            onBlur={guestDetailsForm.handleBlur}
            fullWidth
          >
            <MenuItem value="" disabled className="default-option">
              Gender
            </MenuItem>
            <MenuItem value="MALE">Male</MenuItem>
            <MenuItem value="FEMALE">Female</MenuItem>
          </Select>
          {isFormControlError(guestDetailsForm, "gender") && (
            <FormHelperText className="form-control-error">
              {getFormControlText(guestDetailsForm, "gender")}
            </FormHelperText>
          )}
        </FormControl>

        <FormControl fullWidth size="small">
        <span className="form-control-label">Age Group *</span>
          <Select
            id="ageGroupTypeEnum"
            name="ageGroupTypeEnum"
            value={guestDetailsForm.values.ageGroupTypeEnum}
            placeholder="Age Group"
            displayEmpty
            className="form-select-control"
            onChange={guestDetailsForm.handleChange}
            onBlur={guestDetailsForm.handleBlur}
            fullWidth
          >
            <MenuItem value="" disabled className="default-option">
              Age Group
            </MenuItem>
            <MenuItem value="CHILD">Child</MenuItem>
            <MenuItem value="YOUTH">Youth</MenuItem>
            <MenuItem value="ADULT">Adult</MenuItem>
          </Select>
          {isFormControlError(guestDetailsForm, "ageGroupTypeEnum") && (
            <FormHelperText className="form-control-error">
              {getFormControlText(guestDetailsForm, "ageGroupTypeEnum")}
            </FormHelperText>
          )}
        </FormControl>

        <div className="form-control-row ">
          <FormControl fullWidth size="small">
          <span className="form-control-label">Mobile Number</span>
            <TextField
              id="phone"
              name="phone"
              className="form-input-control"
              size="small"
              placeholder="Mobile Number"
              value={guestDetailsForm.values.phone}
              onChange={(event) => {
                const enteredValue = event.target.value;
                const onlyNumbers = enteredValue.replace(/[^0-9]/g, ""); // Remove any non-numeric characters
                event.target.value = onlyNumbers.slice(0, 10); // Limit to 10 characters
                guestDetailsForm.handleChange(event);
              }}
              onBlur={guestDetailsForm.handleBlur}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">+1</InputAdornment>
                ),
              }}
            />
            {isFormControlError(guestDetailsForm, "phone") && (
              <FormHelperText className="form-control-error">
                {getFormControlText(guestDetailsForm, "phone")}
              </FormHelperText>
            )}
          </FormControl>
        </div>

        <FormControl fullWidth size="small">
        <span className="form-control-label">Email Id</span>
          <TextField
            id="email"
            name="email"
            type="email"
            className="form-input-control"
            size="small"
            placeholder="Email Id"
            value={guestDetailsForm.values.email}
            onChange={guestDetailsForm.handleChange}
            onBlur={guestDetailsForm.handleBlur}
          />
          {isFormControlError(guestDetailsForm, "email") && (
            <FormHelperText className="form-control-error">
              {getFormControlText(guestDetailsForm, "email")}
            </FormHelperText>
          )}
        </FormControl>

        <div className="form-control-row ">
          <FormControl fullWidth size="small">
          <span className="form-control-label">Guest Type *</span>
            <Select
              id="marriageGroupTypeEnum"
              name="marriageGroupTypeEnum"
              value={guestDetailsForm.values.marriageGroupTypeEnum}
              placeholder="Guest Type"
              displayEmpty
              className="form-select-control"
              onChange={guestDetailsForm.handleChange}
              onBlur={guestDetailsForm.handleBlur}
            >
              <MenuItem value="" disabled className="default-option">
                Guest Type
              </MenuItem>
              <MenuItem value="BRIDE">Bride</MenuItem>
              <MenuItem value="GROOM">Groom</MenuItem>
            </Select>
            {isFormControlError(guestDetailsForm, "marriageGroupTypeEnum") && (
              <FormHelperText className="form-control-error">
                {getFormControlText(guestDetailsForm, "marriageGroupTypeEnum")}
              </FormHelperText>
            )}
          </FormControl>

          <FormControl fullWidth size="small">
          <span className="form-control-label">Group</span>
            <Select
              id="groupId"
              name="groupId"
              value={guestDetailsForm.values.groupId}
              placeholder="Group"
              displayEmpty
              className="form-select-control"
              onChange={guestDetailsForm.handleChange}
              onBlur={guestDetailsForm.handleBlur}
            >
              <MenuItem value="" className="default-option">Group</MenuItem>
              {groupList?.map((listItem) => {
                return (
                  <MenuItem key={`Group_${listItem.id}`} value={listItem.id}>
                    {listItem.name}
                  </MenuItem>
                );
              })}
            </Select>
            {isFormControlError(guestDetailsForm, "groupId") && (
              <FormHelperText className="form-control-error">
                {getFormControlText(guestDetailsForm, "groupId")}
              </FormHelperText>
            )}
          </FormControl>
        </div>

        <FormControl fullWidth size="small">
        <span className="form-control-label">Priority *</span>
          <Select
            id="priorityTypeEnum"
            name="priorityTypeEnum"
            value={guestDetailsForm.values.priorityTypeEnum}
            placeholder="Priority"
            displayEmpty
            className="form-select-control"
            onChange={guestDetailsForm.handleChange}
            onBlur={guestDetailsForm.handleBlur}
          >
            <MenuItem value="" disabled className="default-option">
              Priority
            </MenuItem>
            {priorityTypes?.map((priorityListItem) => {
              return (
                <MenuItem
                  key={`priority_${priorityListItem.typeEnum}`}
                  value={priorityListItem.typeEnum}
                >
                  {priorityListItem.name}
                </MenuItem>
              );
            })}
          </Select>
          {isFormControlError(guestDetailsForm, "priorityTypeEnum") && (
            <FormHelperText className="form-control-error">
              {getFormControlText(guestDetailsForm, "priorityTypeEnum")}
            </FormHelperText>
          )}
        </FormControl>
      </form>

      <div className="form-actions-container">
        <Button
          variant="contained"
          className="cancel-button"
          onClick={closePanel}
        >
          Cancel
        </Button>

        <Button
          variant="contained"
          className="submit-button"
          onClick={guestDetailsForm.submitForm}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default AddGuestForm;
