import { ACTION_TYPE_ENUMS } from "./actionTypes";

const openSidePanel = (sidePanelType, selectedData) => {
  return {
    type: ACTION_TYPE_ENUMS.OPEN_SIDE_PANEL,
    payload: {
      sidePanelType,
      selectedData,
    },
  };
};

const updatePriorityTypes = (priorityTypes) => {
  return {
    type: ACTION_TYPE_ENUMS.UPDATE_PRIORITY_TYPES,
    payload: priorityTypes,
  };
};

const updateEventTypes = (eventTypes) => {
  return {
    type: ACTION_TYPE_ENUMS.UPDATE_EVENT_TYPES,
    payload: eventTypes,
  };
};

const updateGroupList = (groupList) => {
  return {
    type: ACTION_TYPE_ENUMS.UPDATE_GROUP_LIST,
    payload: groupList,
  };
};

const updateSelectedGuestList = (guestList) => {
  return {
    type: ACTION_TYPE_ENUMS.UPDATE_SELECTED_GUESTS,
    payload: guestList,
  };
};

const updateSelectedEvent = (event) => {
  return {
    type: ACTION_TYPE_ENUMS.UPDATE_SELECTED_EVENT,
    payload: event,
  };
};

const updateSelectedStatus = (status) => {
  return {
    type: ACTION_TYPE_ENUMS.UPDATE_SELECTED_STATUS,
    payload: status,
  };
};

const updateUserInfo = (userInfo) => {
  return {
    type: ACTION_TYPE_ENUMS.UPDATE_USER_INFO,
    payload: userInfo,
  };
};

const refreshData = () => {
  return {
    type: ACTION_TYPE_ENUMS.REFRESH_DATA,
  };
};

const showLoadingBar = () => {
  return {
    type: ACTION_TYPE_ENUMS.SHOW_LOADER,
  };
};

const hideLoadingBar = () => {
  return {
    type: ACTION_TYPE_ENUMS.HIDE_LOADER,
  };
};

const updateSelectedContact = (contactInfo) => {
  return {
    type: ACTION_TYPE_ENUMS.UPDATE_SELECTED_CONTACT,
    payload: contactInfo,
  };
};

export {
  openSidePanel,
  updateEventTypes,
  updatePriorityTypes,
  updateSelectedEvent,
  updateSelectedGuestList,
  updateUserInfo,
  updateGroupList,
  refreshData,
  showLoadingBar,
  hideLoadingBar,
  updateSelectedStatus,
  updateSelectedContact
};
