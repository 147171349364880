import { RouterProvider, createBrowserRouter } from "react-router-dom";
import "./App.css";
import VerifyRsvp from "./components/VerifyRsvp";
import Dashboard from "./components/Dashboard";
import Homepage from "./components/Homepage";
import Gallery from "./components/Gallery";
import FAQ from "./components/FAQ";

import Chatbot from "./components/chatbot";
import DynamicSlidingPanel from "./components/common/DynamicSlidingPanel";
import RouteLayout from "./components/common/routeLayout";
import GuestRsvpEventList from "./components/Guest/components/GuestRSVPEventList";
import GuestEventList from "./components/Guest/components/GuestEventList";
import EventDetails from "./components/Guest/components/EventDetails";
import EventInvite from "./components/EventInvite";
import Login from "./components/Login";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Profile from "./components/Profile";
import DependentInvites from "./components/Guest/components/DependentInvites";
import TravelInfoPage from "./components/Guest/components/TravelInfoPage";
import Registry from "./components/Guest/components/Registry";
import Messaging from "./components/Messaging";

function App() {
  const routes = createBrowserRouter([
    {
      path: "/verify-rsvp",
      element: <VerifyRsvp />,
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/guest",
      element: <RouteLayout />,
      children: [
        {
          path: "rsvp-event-list",
          element: <GuestRsvpEventList />,
        },
        {
          path: "event-list",
          element: <GuestEventList />,
        },
        {
          path: "group-invite",
          element: <DependentInvites />,
        },
        {
          path: "event/:id",
          element: <EventDetails />,
        },
        {
          path: "",
          element: <Homepage />,
        },
      ],
    },
    {
      path: "/",
      element: <RouteLayout />,
      children: [
        {
          path: "event-invite/:id",
          element: <EventInvite />,
        },
        {
          path: "profile",
          element: <Profile />,
        },
        {
          path: "messages",
          element: <Messaging />,
        },
        {
          path: "/gallery",
          element: <Gallery />,
        },
        {
          path: "/travel-info",
          element: <TravelInfoPage />,
        },
        {
          path: "/faq",
          element: <FAQ />,
        },
        {
          path: "/welcome",
          element: <Homepage />,
        },
        {
          path: "/admin",
          element: <Dashboard />,
        },
        {
          path: "/registry",
          element: <Registry />,
        },
        {
          path: "/chatbot",
          element: <Chatbot />,
        },
        {
          path: "",
          element: <Homepage />,
        },
      ],
    },
  ]);
  return (
    <div className="App">
      <ToastContainer
        autoClose={3000}
        hideProgressBar
        pauseOnFocusLoss={false}
        pauseOnHover={false}
      />
      <DynamicSlidingPanel />
      <RouterProvider router={routes} />
      <div className="chatbot-wrapper">
        <Chatbot />
      </div>
    </div>
  );
}
export default App;
