import "./index.css";

const Footer = () => {
  return (
    <div className="footer-container">
      <span>
        Powered by <a href="http://pherey.com/" target="__blank" className="footer-link">Pherey.com</a>
      </span>
    </div>
  );
};

export default Footer;
