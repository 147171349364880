import { TABLE_TAB_ITEMS, TABLE_TAB_TYPE } from "../../utils/constants";
import "./index.css";
import { Tab, Tabs } from "@mui/material";
import { useEffect, useState } from "react";
import DashboardFilters from "./dashboardFilters";
import TabPanel from "@mui/lab/TabPanel";
import { TabContext } from "@mui/lab";
import CustomTable from "../common/CustomTable";
import { EVENT_TABLE_CONFIG, GUEST_TABLE_CONFIG } from "../../utils/configs";
import {
  getEventList,
  getGroupList,
  getGuestList,
} from "../../services/adminService";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  hideLoadingBar,
  showLoadingBar,
  updateGroupList,
} from "../../actions/actions";
import { getFilteredTableData } from "../../services/formHelperService";

const Dashboard = () => {
  const dispatch = useDispatch();
  const refreshReducer = useSelector((state) => state.refreshReducer);

  const [selectedTab, setSelectedTab] = useState(TABLE_TAB_TYPE.GUEST);
  const [filtersData, setFiltersData] = useState({
    [TABLE_TAB_TYPE.GUEST]: {
      eventId: "",
      groupId: "",
      searchText: "",
      tableData: [],
    },
    [TABLE_TAB_TYPE.EVENTS]: {
      eventId: "",
      groupId: "",
      searchText: "",
      tableData: [],
    },
  });

  const [eventList, setEventsList] = useState([]);
  const [guestList, setGuestList] = useState([]);
  const [groupList, setGroupList] = useState([]);

  const handleTabChange = (event, value) => {
    setSelectedTab(value);
  };

  const fetchEventsList = async () => {
    dispatch(showLoadingBar());
    setEventsList([]);
    try {
      const response = await getEventList();
      setEventsList(response?.data ?? []);
      setFiltersData({
        ...filtersData,
        [TABLE_TAB_TYPE.EVENTS]: {
          ...filtersData[TABLE_TAB_TYPE.EVENTS],
          tableData: response?.data ?? [],
        },
      });
      dispatch(hideLoadingBar());
    } catch (error) {
      dispatch(hideLoadingBar());
      toast.error(error.response.data.message);
    }
  };

  const fetchGuestsList = async () => {
    dispatch(showLoadingBar());
    setGuestList([]);
    try {
      const response = await getGuestList();
      setGuestList(response?.data ?? []);
      setFiltersData({
        ...filtersData,
        [TABLE_TAB_TYPE.GUEST]: {
          ...filtersData[TABLE_TAB_TYPE.GUEST],
          tableData: response?.data ?? [],
        },
      });
      dispatch(hideLoadingBar());
    } catch (error) {
      dispatch(hideLoadingBar());
      toast.error(error.response.data.message);
    }
  };

  const fetchGroupList = async () => {
    dispatch(showLoadingBar());
    setGuestList([]);
    try {
      const response = await getGroupList();
      setGroupList(response?.data ?? []);
      dispatch(updateGroupList(response?.data ?? []));
      dispatch(hideLoadingBar());
    } catch (error) {
      dispatch(hideLoadingBar());
      toast.error(error.response.data.message);
    }
  };

  useEffect(() => {
    fetchEventsList();
    fetchGuestsList();
    fetchGroupList();
  }, [refreshReducer.refresh]);

  const handleFiltersChange = (event) => {
    setFiltersData({
      ...filtersData,
      [selectedTab]: {
        ...filtersData[selectedTab],
        [event.target.name]: event.target.value,
      },
    });
  };

  useEffect(() => {
    const tableData =
      selectedTab === TABLE_TAB_TYPE.GUEST ? guestList : eventList;
    const filters = filtersData[selectedTab];

    const filteredTableData = getFilteredTableData(tableData, filters, selectedTab);
    setFiltersData({
      ...filtersData,
      [selectedTab]: {
        ...filtersData[selectedTab],
        tableData: filteredTableData,
      },
    });
  }, [filtersData]);

  return (
    <>
      <TabContext value={selectedTab}>
        <div className="dashboard-container">
          <div className="tabs-filters-container">
            <div className="tabs-container">
              <Tabs
                value={selectedTab}
                onChange={handleTabChange}
                sx={{
                  "& .MuiTabs-indicator": {
                    backgroundColor: "var(--primary-color)",
                    height: "3px",
                  },
                }}
              >
                {TABLE_TAB_ITEMS.map((tabItem) => {
                  return (
                    <Tab
                      key={tabItem.type}
                      value={tabItem.type}
                      label={tabItem.label}
                    />
                  );
                })}
              </Tabs>
            </div>

            <DashboardFilters
              filtersData={filtersData}
              selectedTab={selectedTab}
              eventList={eventList}
              groupList={groupList}
              handleFiltersChange={handleFiltersChange}
            />
          </div>

          <TabPanel value={TABLE_TAB_TYPE.GUEST} className="tab-panel">
            <CustomTable
              tableConfig={GUEST_TABLE_CONFIG}
              tableData={filtersData[TABLE_TAB_TYPE.GUEST].tableData}
            />
          </TabPanel>

          <TabPanel value={TABLE_TAB_TYPE.EVENTS} className="tab-panel">
            <CustomTable
              tableConfig={EVENT_TABLE_CONFIG}
              tableData={filtersData[TABLE_TAB_TYPE.EVENTS].tableData}
            />
          </TabPanel>
        </div>
      </TabContext>
    </>
  );
};

export default Dashboard;
