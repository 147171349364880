import { toast } from "react-toastify";
import { updatedEventStatusForGuests } from "../../../services/adminService";
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  getFormControlText,
  isFormControlError,
} from "../../../services/formHelperService";
import { useDispatch, useSelector } from "react-redux";
import {
  hideLoadingBar,
  refreshData,
  showLoadingBar,
} from "../../../actions/actions";
import "../index.css";
import { RSVP_EVENT_STATUS } from "../../../utils/constants";

const EventStatusForm = ({ closePanel }) => {
  const dispatch = useDispatch();

  const selectedDataReducer = useSelector((state) => state.selectedDataReducer);
  const selectedGuestList = selectedDataReducer.selectedGuestList;
  const selectedStatus = selectedDataReducer.selectedStatus;
  const selectedEvent = selectedDataReducer.selectedEvent;

  const initialValues = {
    note: "",
  };

  const eventStatusForm = useFormik({
    initialValues,
    validationSchema: Yup.object({
      note: Yup.string(),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      handleFormSubmit(values);
    },
  });

  const handleFormSubmit = (values) => {
    updateGuestEventStatus(values);
  };

  const updateGuestEventStatus = async (values) => {
    const formData = {
      idList: [],
      note: values.note,
    };

    selectedGuestList.forEach((listItem) => {
      formData.idList.push(listItem.id);
    });
    dispatch(showLoadingBar());
    try {
      await updatedEventStatusForGuests(
        selectedEvent.id,
        selectedStatus,
        formData
      );
      dispatch(refreshData());
      dispatch(hideLoadingBar());
      toast.success("Guest Invite Status has been updated successfully.");
      closePanel();
    } catch (error) {
      dispatch(hideLoadingBar());
      toast.error(error.response.data.message);
    }
  };

  const getSubmitLabel = () => {
    return selectedStatus === RSVP_EVENT_STATUS.ACCEPTED ? "Accept" : "Decline";
  };

  return (
    <div className="side-panel-root">
      <form className="form-container">
        <FormControl fullWidth size="small">
          <span className="form-control-label">Status Note</span>
          <FormControl fullWidth size="small">
            <TextField
              id="note"
              name="note"
              className="form-note-input"
              size="small"
              placeholder="Kindly offer your feedback or Share your reasons for declining here..."
              onChange={eventStatusForm.handleChange}
              onBlur={eventStatusForm.handleBlur}
              multiline
              rows={2}
            />
          </FormControl>
          {isFormControlError(eventStatusForm, "note") && (
            <FormHelperText className="form-control-error">
              {getFormControlText(eventStatusForm, "note")}
            </FormHelperText>
          )}
        </FormControl>

        <div className="guest-list-container">
          <span className="guest-list-heading">Selected Guests</span>
          <Grid container spacing={1}>
            {selectedGuestList.map((guestItem, index) => {
              return (
                <Grid item xs={12} lg={6}>
                  <span>
                    {index + 1}.&nbsp;&nbsp;{guestItem.name}
                  </span>
                </Grid>
              );
            })}
          </Grid>
        </div>
      </form>

      <div className="form-actions-container">
        <Button
          variant="contained"
          className="cancel-button"
          onClick={closePanel}
        >
          Cancel
        </Button>

        <Button
          variant="contained"
          className="submit-button"
          onClick={eventStatusForm.submitForm}
        >
          {getSubmitLabel()}
        </Button>
      </div>
    </div>
  );
};

export default EventStatusForm;
