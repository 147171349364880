import FlightRoundedIcon from "@mui/icons-material/FlightRounded";
import LuggageIcon from '@mui/icons-material/Luggage';
import { Button, Link } from "@mui/material";
import TrainRoundedIcon from '@mui/icons-material/TrainRounded';
import "./guestStyles.css";
import { LANDING_PAGE_LINKS } from "../../../utils/constants";
import Chatbot from "../../chatbot";

const TravelInfoPage = () => {
  const handleOpenLink = (link) => {
    window.open(link, "_blank");
  };
  return (
    <div className="landing-root">
      <div className="landing-details-section">
        <LuggageIcon className="landing-details-icon" />
        <span className="landing-details-heading">Lansdowne Resort</span>
        <span className="landing-details">
          Please access this document for more information on how to book rooms
          at our venue: 
          <Link
            href={LANDING_PAGE_LINKS.document} underline="none"
            target="_blank" rel="noopener"
            className="landing-details-link"
            variant="body2"
          >
            &nbsp;View Document
          </Link>
        </span>
        <span>The deadline to book rooms at our venue is July 20th, 2024.</span>
        <Button
          variant="text"
          className="button"
          onClick={() => handleOpenLink(LANDING_PAGE_LINKS.hotel)}
        >
          Make Reservation
        </Button>
        
      </div>

      <div className="landing-details-section">
        <FlightRoundedIcon className="landing-details-icon" />
        <span className="landing-details-heading">
          Dulles International Airport / Reagan National Airport
        </span>
        <span className="landing-details">
          For our out of town guests, we recommend flying into Dulles
          International Airport or Reagan National Airport! Both airports are
          30-40 minutes from our venue.
        </span>
      </div>

      <div className="landing-details-section">
        <TrainRoundedIcon className="landing-details-icon" />
        <span className="landing-details-heading">Amtrak</span>
        <span className="landing-details">
          For our east coast guests who would like to take the train, Amtrak is
          a very convenient option! Please select Union Station in Washington,
          D.C. as your arrival destination. The station is about 60 minutes from
          our venue.
        </span>
      </div>
      {/* <div className="chatbot-wrapper">
                <Chatbot />
            </div> */}
    </div>
  );
};

export default TravelInfoPage;
