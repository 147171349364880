import { ACTION_TYPE_ENUMS } from "../actions/actionTypes";

const initialState = {
  eventTypes: [],
  priorityTypes: [],
  groupList: [],
};

const masterDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPE_ENUMS.UPDATE_PRIORITY_TYPES:
      return {
        ...state,
        priorityTypes: action.payload
      };
    case ACTION_TYPE_ENUMS.UPDATE_EVENT_TYPES:
      return {
        ...state,
        eventTypes: action.payload
      };
      case ACTION_TYPE_ENUMS.UPDATE_GROUP_LIST:
      return {
        ...state,
        groupList: action.payload
      };
    default:
      return state;
  }
};

export default masterDataReducer;
