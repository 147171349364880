import "./index.css";
import ContactsList from "./ContactsList/ContactsList";
import MessageWindow from "./MessageWindow/MessageWindow";
import { useMediaQuery } from "@mui/material";
import { useSelector } from "react-redux";

const Messaging = () => {
  const isSmallScreen = useMediaQuery("(max-width:1024px)");

  const messagingReducer = useSelector((state) => state.messagingReducer);
  const contact = messagingReducer.selectedContact;

  const getMemberListStyles = () => {
    // if (isSmallScreen && !contact) {
    //   return "";
    // }
    // return "message-member-list-container-hidden";

    return !isSmallScreen || (isSmallScreen && !contact);
  };

  const getMessageWindowStyles = () => {
    // if (isSmallScreen && contact) {
    //   return "";
    // }
    // return "message-member-list-container-hidden";
    return !isSmallScreen || (isSmallScreen && contact);
  };

  return (
    <div className="messages-main-container">
      <div className="messages-root">
        {getMemberListStyles() ? (
          <div className="message-member-list-container">
            <ContactsList />
          </div>
        ) : null}

        {getMessageWindowStyles() ? (
          <div className="message-container">
            <MessageWindow />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Messaging;
