import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import useMediaQuery from '@mui/material/useMediaQuery';

import "./gallery.css";

const Gallery = () => {
    const isSmallScreen = useMediaQuery('(max-width:1024px)');

    const itemData = [
        {
            img: 'https://images.zola.com/3f835f30-2eb2-4ab0-a617-b23a9a3e2168',
            title: "Vishnu Razdan and Mouna Allani's Gallery",
            rows: 3,
            cols: 2
        },
        {
            img: 'https://images.zola.com/16416eb0-9521-4766-aeac-1080a8a9a9d3',
            title: "Vishnu Razdan and Mouna Allani's Gallery",
            rows: 3,
            cols: 2
        },
        {
            img: 'https://images.zola.com/f594d927-0c42-49f6-8d0c-db95f305f8bb',
            title: "Vishnu Razdan and Mouna Allani's Gallery",
            rows: 2,
            cols: 1
        },
        {
            img: 'https://images.zola.com/aef3a7ab-f503-4b4f-8a26-82404e1c823a',
            title: "Vishnu Razdan and Mouna Allani's Gallery",
            rows: 2,
            cols: 2
        },
        {
            img: 'https://images.zola.com/08ffe3eb-7ced-4d70-85e4-2f0735f1cfed',
            title: "Vishnu Razdan and Mouna Allani's Gallery",
            rows: 2,
            cols: 1
        },
        {
            img: 'https://images.zola.com/8724a873-6828-4577-aec8-db493e713a2f',
            title: "Vishnu Razdan and Mouna Allani's Gallery",
            rows: isSmallScreen ? 2 :  3,
            cols: isSmallScreen ? 2 : 2
        },
        {
            img: 'https://images.zola.com/7c61204f-d053-4eff-a3d0-9c0327b70daa',
            title: "Vishnu Razdan and Mouna Allani's Gallery",
            rows: isSmallScreen ? 1 : 3,
            cols: isSmallScreen ? 1 : 2
        },
        {
            img: 'https://images.zola.com/22fb3141-bff8-4225-80b4-0ea8cfdfc9a5',
            title: "Vishnu Razdan and Mouna Allani's Gallery",
            rows: isSmallScreen ? 2 : 3,
            cols: isSmallScreen ? 2 : 4
        }
      ];
    return (
        <div className="gallery-container">
            <ImageList
                sx={{ width: isSmallScreen ? 400: 1600 }}
                variant="quilted"
                cols={isSmallScreen ? 1 : 4}
                rowHeight={300}
                >
                    {itemData.map((item) => (
                        <ImageListItem key={item.img} cols={item.cols || 1} rows={item.rows || 1}>
                        <img
                            src= {item.img}
                            alt={item.title}
                            loading="lazy"
                        />
                        </ImageListItem>
                    ))}
            </ImageList>
        </div>
    );
};
export default Gallery;