import { ACTION_TYPE_ENUMS } from "../actions/actionTypes";

const initialState = {
  selectedGuestList: [],
  selectedEvent: null,
  selectedStatus: null,
};

const selectedDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPE_ENUMS.UPDATE_SELECTED_GUESTS:
      return {
        ...state,
        selectedGuestList: action.payload,
      };
    case ACTION_TYPE_ENUMS.UPDATE_SELECTED_EVENT:
      return {
        ...state,
        selectedEvent: action.payload,
      };
    case ACTION_TYPE_ENUMS.UPDATE_SELECTED_STATUS:
      return {
        ...state,
        selectedStatus: action.payload,
      };
    default:
      return state;
  }
};

export default selectedDataReducer;
