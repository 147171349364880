import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import SearchOffRoundedIcon from "@mui/icons-material/SearchOffRounded";
import {
  FormControl,
  IconButton,
  InputAdornment,
  Modal,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { hideLoadingBar, showLoadingBar } from "../../../actions/actions.js";
import {
  getConversations,
  readAllMessageByConversationId,
} from "../../../services/individualService.js";
import { isAdminRole } from "../../../utils/commonMethods";
import ContactTile from "../ContactTile";
import "../index.css";
import NewBroadCastMessage from "../NewBroadcastMessage.js";
import "./ContactsList.css";
import { filterConversationBySearchText as filterConversationsBySearchText } from "../../../services/formHelperService.js";

const ContactsList = ({}) => {
  const dispatch = useDispatch();
  const [isBroadcastModalOpen, setIsBroadcastModalOpen] = useState(false);
  const [conversationList, setConversationList] = useState([]);

  const [isSearchTextOpen, setIsSearchTextOpen] = useState(false);
  const [searchText, setSearchText] = useState("");

  const [filteredConversationList, setFilteredConversationList] = useState([]);

  const userInfoReducer = useSelector((state) => state.userInfoReducer);

  const userInfo = userInfoReducer?.userInfo;

  const closeModal = () => {
    setIsBroadcastModalOpen(false);
    fetchAllConversations();
  };

  const fetchAllConversations = async () => {
    dispatch(showLoadingBar());
    setConversationList([]);
    try {
      const response = await getConversations();
      setConversationList(response?.data ?? []);
      setFilteredConversationList(response?.data ?? []);
      dispatch(hideLoadingBar());
    } catch (error) {
      dispatch(hideLoadingBar());
      toast.error(error.response.data.message);
    }
  };

  useEffect(() => {
    fetchAllConversations();
    const intervalId = setInterval(() => {
      fetchAllConversations();
    }, 30000); // 30 seconds interval

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    updateConversations();
  }, [searchText]);

  const handleConversationSelect = async (conversationId) => {
    if (!isAdminRole(userInfo)) {
      dispatch(showLoadingBar());
      try {
        await readAllMessageByConversationId(conversationId);
        dispatch(hideLoadingBar());
        fetchAllConversations();
      } catch (error) {
        dispatch(hideLoadingBar());
        toast.error(error.response.data.message);
      }
    }
  };

  const updateConversations = () => {
    const filteredConversations = filterConversationsBySearchText(
      conversationList,
      searchText
    );
    setFilteredConversationList(filteredConversations);
  };

  return (
    <>
      <Modal
        id="broadCastModal"
        open={isBroadcastModalOpen}
        onClose={closeModal}
      >
        <div className="modal-root modal-container">
          <NewBroadCastMessage closeModal={closeModal} />
        </div>
      </Modal>
      <div className="chat-header-container">
        {isSearchTextOpen ? (
          <div className="chat-search-container">
            <FormControl fullWidth size="small">
              <TextField
                id="searchText"
                size="small"
                placeholder="Search here ...."
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchRoundedIcon />
                    </InputAdornment>
                  ),
                }}
                sx={{
                  backgroundColor: "#F7F7F7 !important",
                  borderRadius: "50px !important",
                  height: "35px !important",
                  border: "unset !important",
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "50px !important",
                    height: "35px !important",
                    border: "unset !important",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "unset !important",
                  },
                }}
                value={searchText}
                onChange={(event) => {
                  setSearchText(event.target.value);
                }}
              />
            </FormControl>

            <IconButton
              onClick={() => {
                setIsSearchTextOpen(false);
              }}
            >
              <SearchOffRoundedIcon />
            </IconButton>
          </div>
        ) : (
          <>
            <div className="chat-header">Messages</div>

            <IconButton
              onClick={() => {
                setIsSearchTextOpen(true);
              }}
            >
              <SearchRoundedIcon />
            </IconButton>
          </>
        )}
      </div>
      <div className="chat-container">
        {/* Container to show list of contacts */}
        <div className="contact-list-container">
          {filteredConversationList.map((contactListItem) => {
            return (
              <div key={contactListItem.id} className="contact-list-item">
                <ContactTile
                  contactDetails={contactListItem}
                  handleConversationSelect={handleConversationSelect}
                />
              </div>
            );
          })}
        </div>
      </div>
      {/* {isAdminRole(userInfo) ? (
        <div className="chat-footer-container">
          <div className="chat-footer">
            <Button
              className="new-chat-button"
              variant="text"
              onClick={() => {
                setIsBroadcastModalOpen(true);
              }}
            >
              <MapsUgcIcon /> <span>New Chat</span>
            </Button>
          </div>
        </div>
      ) : null} */}
    </>
  );
};

export default ContactsList;
