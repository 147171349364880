import { ACTION_TYPE_ENUMS } from "../actions/actionTypes";

const initialState = {
  refresh: false,
  loading: false,
};

const refreshReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPE_ENUMS.REFRESH_DATA:
      return {
        ...state,
        refresh: !state.refresh,
      };
    case ACTION_TYPE_ENUMS.SHOW_LOADER:
      return {
        ...state,
        loading: true,
      };
    case ACTION_TYPE_ENUMS.HIDE_LOADER:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default refreshReducer;
