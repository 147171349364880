import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  getEventList,
  inviteGuestsByEventId,
} from "../../../services/adminService";
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  getFormControlText,
  isFormControlError,
} from "../../../services/formHelperService";
import { FORM_VALIDATION_MESSAGES } from "../../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import {
  hideLoadingBar,
  refreshData,
  showLoadingBar,
} from "../../../actions/actions";
import "../index.css";

const InviteGuests = ({ closePanel }) => {
  const dispatch = useDispatch();

  const selectedDataReducer = useSelector((state) => state.selectedDataReducer);
  const selectedGuestList = selectedDataReducer.selectedGuestList;
  const [eventList, setEventsList] = useState([]);

  const initialValues = {
    eventId: "",
  };

  const fetchEventsList = async () => {
    setEventsList([]);
    dispatch(showLoadingBar());
    try {
      const response = await getEventList();
      setEventsList(response?.data ?? []);
      dispatch(hideLoadingBar());
    } catch (error) {
      dispatch(hideLoadingBar());
      toast.error(error.response.data.message);
    }
  };

  useEffect(() => {
    fetchEventsList();
  }, []);

  const eventInviteForm = useFormik({
    initialValues,
    validationSchema: Yup.object({
      eventId: Yup.string().required(FORM_VALIDATION_MESSAGES.SELECT_EVENT),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      handleFormSubmit(values);
    },
  });

  const handleFormSubmit = (values) => {
    sendInvites(values);
  };

  const sendInvites = async (eventData) => {
    const formData = {
      idList: [],
    };

    selectedGuestList.forEach((listItem) => {
      formData.idList.push(listItem.id);
    });
    dispatch(showLoadingBar());

    try {
      await inviteGuestsByEventId(eventData.eventId, formData);
      dispatch(refreshData());
      closePanel();
      dispatch(hideLoadingBar());
      toast.success("Invites have been sent successfully.");
    } catch (error) {
      dispatch(hideLoadingBar());
      toast.error(error.response.data.message);
    }
  };

  return (
    <div className="side-panel-root">
      <form className="form-container">
        <FormControl fullWidth size="small">
          <span className="form-control-label">Event *</span>
          <Select
            id="eventId"
            name="eventId"
            value={eventInviteForm.values.eventId}
            placeholder="Select Event"
            displayEmpty
            className="form-select-control"
            onChange={eventInviteForm.handleChange}
            onBlur={eventInviteForm.handleBlur}
            fullWidth
          >
            <MenuItem value="" disabled className="default-option">
              Select Event
            </MenuItem>
            {eventList?.map((eventListItem) => {
              return (
                <MenuItem value={eventListItem.id}>
                  {eventListItem.name}
                </MenuItem>
              );
            })}
          </Select>
          {isFormControlError(eventInviteForm, "eventId") && (
            <FormHelperText className="form-control-error">
              {getFormControlText(eventInviteForm, "eventId")}
            </FormHelperText>
          )}
        </FormControl>

        <div className="guest-list-container">
          <span className="guest-list-heading">Selected Guests</span>
          <Grid container>
            {selectedGuestList.map((guestItem, index) => {
              return (
                <Grid item xs={12} lg={6}>
                  <span>
                    {index + 1}.&nbsp;&nbsp;{guestItem.name}
                  </span>
                </Grid>
              );
            })}
          </Grid>
        </div>
      </form>

      <div className="form-actions-container">
        <Button
          variant="contained"
          className="cancel-button"
          onClick={closePanel}
        >
          Cancel
        </Button>

        <Button
          variant="contained"
          className="submit-button"
          onClick={eventInviteForm.submitForm}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default InviteGuests;
