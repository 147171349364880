import { useLocation, useNavigate } from "react-router-dom";
import "./guestStyles.css";
import { ROUTES_LIST } from "../../../utils/sidebarConstants";
import { USER_ROLES } from "../../../utils/constants";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import { useDispatch, useSelector } from "react-redux";
import { updateSelectedContact } from "../../../actions/actions";

const Sidebar = ({ closeSidebar }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const userInfoReducer = useSelector((state) => state.userInfoReducer);
  const userInfo = userInfoReducer?.userInfo;

  const pathArray = location.pathname.split("/");

  const clearSelectedContact = () => {
    dispatch(updateSelectedContact(null));
  };

  const handleRouteChange = (route) => {
    navigate(route);
    clearSelectedContact();
    closeSidebar();
  };

  const isCurrentPath = (route) => {
    return location.pathname.includes(route);
  };

  const isGroupLeader = () => {
    return userInfo?.groupLeader;
  };

  const handleLogOut = () => {
    sessionStorage.clear();
    navigate("/login");
  };

  return (
    <div className="sidebar-route-container">
      <span
        className={`sidebar-route ${
          location.pathname === "/" ? "selected-route" : ""
        }`}
        onClick={() => handleRouteChange("/")}
      >
        Home
      </span>

      {userInfo?.roleType?.typeEnum !== USER_ROLES.admin ? (
        <>
          {ROUTES_LIST.map((routeListItem) => {
            return (
              <span
                className={`sidebar-route ${
                  isCurrentPath(routeListItem.route) ? "selected-route" : ""
                }`}
                onClick={() => handleRouteChange(routeListItem.route)}
                key={`route__${routeListItem.key}`}
              >
                {routeListItem.label}
              </span>
            );
          })}
          {isGroupLeader() ? (
            <span
              className={`sidebar-route ${
                isCurrentPath("/guest/group-invite") ? "selected-route" : ""
              }`}
              onClick={() => handleRouteChange("/guest/group-invite")}
            >
              My Group Invites
            </span>
          ) : (
            <span
              className={`sidebar-route ${
                isCurrentPath("/guest/rsvp-event-list") ? "selected-route" : ""
              }`}
              onClick={() => handleRouteChange("/guest/rsvp-event-list")}
            >
              My Invites
            </span>
          )}
        </>
      ) : (
        <span
          className={`sidebar-route ${
            isCurrentPath("admin") ? "selected-route" : ""
          }`}
          onClick={() => handleRouteChange("/admin")}
        >
          Admin Dashboard
        </span>
      )}

      <span
        className={`sidebar-route ${
          isCurrentPath("travel-info") ? "selected-route" : ""
        }`}
        onClick={() => handleRouteChange("/travel-info")}
      >
        Travel
      </span>
      <span
        className={`sidebar-route ${
          isCurrentPath("gallery") ? "selected-route" : ""
        }`}
        onClick={() => handleRouteChange("/gallery")}
      >
        Gallery
      </span>
      <span
        className={`sidebar-route ${
          isCurrentPath("faq") ? "selected-route" : ""
        }`}
        onClick={() => handleRouteChange("/faq")}
      >
        FAQ
      </span>
      <span
        className={`sidebar-route ${
          isCurrentPath("registry") ? "selected-route" : ""
        }`}
        onClick={() => handleRouteChange("/registry")}
      >
        Gift Registry
      </span>
      <span
        className={`sidebar-route ${
          isCurrentPath("profile") ? "selected-route" : ""
        }`}
        onClick={() => handleRouteChange("/profile")}
      >
        My Profile
      </span>

      <span
        className={`sidebar-route ${
          isCurrentPath("messages") ? "selected-route" : ""
        }`}
        onClick={() => handleRouteChange("/messages")}
      >
        My Messages
      </span>

      <div className="logout-section" onClick={handleLogOut}>
        <LogoutRoundedIcon />
        <span>Logout</span>
      </div>
    </div>
  );
};

export default Sidebar;
