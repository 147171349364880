import dayjs from "dayjs";
import GroupsRoundedIcon from "@mui/icons-material/GroupsRounded";
import { CONVERSATION_TYPE_ENUM, USER_ROLES } from "./constants";
import ManageAccountsRoundedIcon from "@mui/icons-material/ManageAccountsRounded";

const getDefaultDateTimeFormatter = (date) => {
  return dayjs(date).format("DD MMMM YYYY, hh:mm A");
};

const getDefaultTimeFormatter = (date) => {
  return dayjs(date).format("hh:mm A");
};

const getFullName = (nameList) => {
  return nameList.filter((nameListItem) => nameListItem).join(" ");
};

const getContactName = (contactDetails) => {
  return contactDetails.name;
};

const getContactAvatarLogo = (contactDetails) => {
  switch (contactDetails.conversationTypeEnum) {
    case CONVERSATION_TYPE_ENUM.ADMIN:
      return <ManageAccountsRoundedIcon />;
    case CONVERSATION_TYPE_ENUM.GROUP:
      return <GroupsRoundedIcon />;
    default:
      return getContactInitials(contactDetails?.name);
  }
};

const getContactInitials = (name) => {
  const nameArray = name?.split(" ");
  const initials = nameArray
    .reduce((acc, current) => {
      if (acc.length < 2) {
        acc.push(current.charAt(0));
      }
      return acc;
    }, [])
    .join("");

  return initials;
};

const isAdminRole = (userDetails) => {
  return userDetails?.roleType?.typeEnum === USER_ROLES.admin;
};

export {
  getDefaultDateTimeFormatter,
  getDefaultTimeFormatter,
  getFullName,
  getContactName,
  getContactAvatarLogo,
  isAdminRole,
  getContactInitials,
};
