import './noContent.css';
import SelectChat from '../../../assets/Messages/SelectChat.svg'

const SelectConversation = () => {
    return <div className='no-content-main-container'>
        <div className='no-content-image-container'>
            <img src={SelectChat} alt='Select Conversation' loading='lazy' className='no-content-image' />
        </div>
        <div className='no-content-description-container'>
            <span className='no-content-description'>Select a chat from the list to view messages</span>
        </div>
    </div>
}

export default SelectConversation;