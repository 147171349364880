import dayjs from "dayjs";
import {
  AGE_GROUP_LABEL,
  GENDER_TYPE_LABEL,
  TABLE_TAB_TYPE,
} from "../utils/constants";
import DateIcon from "../assets/DateIcon.svg";

const getGuestName = (data) => {
  return `${data.name} (${GENDER_TYPE_LABEL[data.gender]} / ${
    AGE_GROUP_LABEL[data.ageGroupType.typeEnum]
  })`;
};

const getPercentage = (actual, total) => {
  if (actual) {
    return ((actual / total) * 100).toFixed(2);
  }
  return 0;
};

const getGuestsAttending = (data) => {
  const { acceptedGuests, guestsInvited } = data;
  return `${acceptedGuests ?? 0} of ${guestsInvited ?? 0} (${getPercentage(
    acceptedGuests ?? 0,
    guestsInvited ?? 0
  )}%)`;
};

const formatDataByType = (fieldType, value) => {
  switch (fieldType) {
    default:
      return value;
  }
};

const getEventDateTime = (data) => {
  return (
    <div className="event-date-time-container">
      <div className="event-date-time-image">
        <img src={DateIcon} alt="Date" />
      </div>
      <div className="event-dates">
        <span className="event-date-time">
          {dayjs(data.startDate).format("DD-MMM-YYYY | HH:mm")}
        </span>
        <span className="event-date-time">
          {dayjs(data.endDate).format("DD-MMM-YYYY | HH:mm")}
        </span>
      </div>
    </div>
  );
};

const dataFormatter = (fieldType, fieldKey, rowData) => {
  switch (fieldKey) {
    case "name":
      return getGuestName(rowData);
    case "eventName":
      return rowData.name;
    case "guestTypeName":
      return rowData.marriageGroupType.name;
    case "priority":
      return rowData.priorityType.name;
    case "guestsAttending":
      return getGuestsAttending(rowData);
    case "eventDateTime":
      return getEventDateTime(rowData);
    default:
      return formatDataByType(fieldType, rowData[fieldKey]);
  }
};

const isFormControlError = (form, key) => {
  return form.touched[key] && Boolean(form.errors[key]);
};

const getFormControlText = (form, key) => {
  return form.touched[key] && form.errors[key];
};

const getFormControlValue = (value) => {
  return value ?? "";
};

const formDateTimeToString = (dateTime) => {
  return dayjs()
    .year(dateTime.year)
    .month(dateTime.month - 1) // Day.js month is 0-based (0-Jan, 1-Feb, ..., 11-Dec)
    .date(dateTime.day)
    .hour(dateTime.hour)
    .minute(dateTime.minute)
    .format("YYYY-MM-DD HH:mm:ss");
};

const convertStringToDateTime = (dateTime) => {
  const date = dayjs(dateTime);

  return {
    year: date.year(),
    month: date.month() + 1,
    day: date.day(),
    hour: date.hour(),
    minute: date.minute(),
  };
};

const getDateSuffix = (date) => {
  const dayOfMonth = dayjs(date).format("D");
  let suffix = "th";
  if (dayOfMonth === "1" || dayOfMonth === "21" || dayOfMonth === "31") {
    suffix = "st";
  } else if (dayOfMonth === "2" || dayOfMonth === "22") {
    suffix = "nd";
  } else if (dayOfMonth === "3" || dayOfMonth === "23") {
    suffix = "rd";
  }
  return suffix;
};

const getFilteredTableData = (tableData, filters, selectedTab = null) => {
  let filteredData = [...tableData];

  if (filters.eventId) {
    filteredData = filteredData.filter((item) => {
      const eventIdMatch =
        selectedTab === TABLE_TAB_TYPE.GUEST
          ? !item.hasOwnProperty("eventId") || item.eventId === filters.eventId
          : !item.hasOwnProperty("id") || item.id === filters.eventId;

      return eventIdMatch;
    });
  }

  if (filters.groupId) {
    filteredData = filteredData.filter((item) => {
      const groupIdMatch =
        !item.hasOwnProperty("groupId") || item.groupId === filters.groupId;

      return groupIdMatch;
    });
  }

  if (filters.searchText) {
    filteredData = filteredData.filter((item) => {
      const nameMatch =
        item.name &&
        item.name.toLowerCase().includes(filters.searchText.toLowerCase());

      const phoneNumberMatch =
        item.phone &&
        item.phone.toLowerCase().includes(filters.searchText.toLowerCase());

      const emailIdMatch =
        item.email &&
        item.email.toLowerCase().includes(filters.searchText.toLowerCase());

      return nameMatch || phoneNumberMatch || emailIdMatch;
    });
  }

  if (filters.rsvpStatusTypeEnum) {
    filteredData = filteredData.filter((item) => {
      const sharingStatusTypeMatch =
        !item.hasOwnProperty("sharingStatusType") ||
        item.sharingStatusType?.typeEnum === filters.rsvpStatusTypeEnum;

      return sharingStatusTypeMatch;
    });
  }

  return filteredData;
};

const filterConversationBySearchText = (conversationList, searchText) => {
  let filteredList = [...conversationList];

  if(searchText){
    filteredList = filteredList.filter((item) => {
      const nameMatch =
        item.name &&
        item.name.toLowerCase().includes(searchText.toLowerCase());

      const descriptionMatch =
        item.description &&
        item.description.toLowerCase().includes(searchText.toLowerCase());

      

      return nameMatch || descriptionMatch;
    });
  }

  return filteredList;
}

export {
  dataFormatter,
  isFormControlError,
  getFormControlText,
  getFormControlValue,
  formDateTimeToString,
  convertStringToDateTime,
  getDateSuffix,
  getFilteredTableData,
  filterConversationBySearchText
};
