import { useSelector } from "react-redux";
import "./index.css";
import { Button, Checkbox, FormControlLabel } from "@mui/material";
import { CONSENT_MESSAGES } from "../../utils/constants";
import { useNavigate } from "react-router-dom";

const Profile = () => {
  const userInfoReducer = useSelector((state) => state.userInfoReducer);
  const userInfo = userInfoReducer?.userInfo;

  const navigate = useNavigate();

  const getFormattedValue = (value) => {
    return value ?? "---";
  };

  const handleLogOut = () => {
    sessionStorage.clear();
    navigate("/login");
  };

  return (
    <div className="profile-details-container">
      <span className="profile-details-heading">My Details</span>
      <div className="profile-details">
        <span className="profile-data-value">
          {getFormattedValue(userInfo?.name)}
        </span>
        <span className="profile-data-label">Name</span>
      </div>
      <div className="profile-details">
        <span className="profile-data-value">
          {getFormattedValue(userInfo?.email)}
        </span>
        <span className="profile-data-label">Email Id</span>
      </div>
      <div className="profile-details">
        <span className="profile-data-value">
          {getFormattedValue(userInfo?.phone)}
        </span>
        <span className="profile-data-label">Mobile Number</span>
      </div>
      <hr className="separator" />
      <FormControlLabel
        control={<Checkbox checked={true} disabled />}
        label={CONSENT_MESSAGES.phone}
      />
      <hr className="separator" />
      <Button className="button" onClick={handleLogOut}>
        Logout
      </Button>
    </div>
  );
};

export default Profile;
