import {
  Button,
  FormControl,
  IconButton,
  Menu,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import "./index.css";
import { FIELD_TYPE_ENUMS, MORE_ACTION_TYPE } from "../../utils/configs";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  dataFormatter,
  getFilteredTableData,
} from "../../services/formHelperService";
import {
  getEventById,
  getGuestListByEventId,
  inviteGuestsByEventId,
  unInviteGuestsByEventId,
} from "../../services/adminService";
import { toast } from "react-toastify";
import { hideLoadingBar, openSidePanel, showLoadingBar, updateSelectedEvent, updateSelectedGuestList, updateSelectedStatus } from "../../actions/actions";
import {
  RSVP_EVENT_STATUS,
  RSVP_EVENT_STATUS_LIST,
} from "../../utils/constants";
import { useParams } from "react-router-dom";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const EventInvite = () => {
  const dispatch = useDispatch();

  const refreshReducer = useSelector((state) => state.refreshReducer);


  const [eventDetails, setEventDetails] = useState(null);
  const { id } = useParams();

  const [guestList, setGuestList] = useState([]);
  const [selectedGuestList, setSelectedGuestList] = useState([]);

  const [moreActionsElement, setMoreActionsElement] = useState(null);
  const isMoreActionsMenuOpen = Boolean(moreActionsElement);
  const [selectedRow, setSelectedRow] = useState(null);

  const [filtersData, setFiltersData] = useState({
    rsvpStatusTypeEnum: "",
    tableData: [],
  });

  const fetchEvent = async () => {
    setEventDetails([]);
    dispatch(showLoadingBar());
    try {
      const response = await getEventById(id);
      setEventDetails(response?.data);
      dispatch(hideLoadingBar());
    } catch (error) {
      dispatch(hideLoadingBar());
      toast.error(error.response.data.message);
    }
  };

  const getStatus = (rowData) => {
    return (
      <span className="invited-status-label">
        {rowData.sharingStatusType ? "Invited" : "Not Invited"}
      </span>
    );
  };

  const getInviteButton = (rowData) => {
    if (!rowData.sharingStatusType) {
      return (
        <Button
          variant="contained"
          className="invite-button"
          onClick={() => {
            handleSingleInvite(rowData);
          }}
        >
          Invite
        </Button>
      );
    }

    return (
      <Button
        variant="contained"
        className="invite-button"
        onClick={() => {
          handleSingleUnInvite(rowData);
        }}
      >
        Un Invite
      </Button>
    );
  };

  const fetchGuestsList = async () => {
    dispatch(showLoadingBar());
    setGuestList([]);
    try {
      const response = await getGuestListByEventId(id);
      setGuestList(response?.data ?? []);
      setFiltersData({
        ...filtersData,
        tableData: response?.data ?? [],
      });
      dispatch(hideLoadingBar());
    } catch (error) {
      dispatch(hideLoadingBar());
      toast.error(error.response.data.message);
    }
  };

  useEffect(() => {
    if (id) {
      fetchEvent();
    }
  }, [id]);

  useEffect(() => {
    if (eventDetails) {
      fetchGuestsList();
    }
  }, [eventDetails]);

  useEffect(() => {
    const filteredTableData = getFilteredTableData(guestList, filtersData);
    setFiltersData({
      ...filtersData,
      tableData: filteredTableData,
    });
  }, [filtersData]);

  useEffect(() => {
    fetchGuestsList();
  }, [refreshReducer.refresh]);

  const isRowDataSelected = (rowData) => {
    const index = selectedGuestList.findIndex(
      (listItem) => listItem.id === rowData.id
    );
    return index !== -1;
  };

  const handleRowSelect = (rowData) => {
    const index = selectedGuestList.findIndex(
      (listItem) => listItem.id === rowData.id
    );

    const updatedSelectedList = [...selectedGuestList];

    if (index !== -1) {
      updatedSelectedList.splice(index, 1);
    } else {
      updatedSelectedList.push(rowData);
    }

    setSelectedGuestList(updatedSelectedList);
  };

  const handleMultiSelect = (event) => {
    let updatedSelectedList = [];

    if (event.target.checked) {
      updatedSelectedList = [...guestList];
    } else {
      updatedSelectedList = [];
    }

    setSelectedGuestList(updatedSelectedList);
  };

  const isAllSelected = () => {
    return selectedGuestList.length === guestList.length;
  };

  const sendInvites = async (formData) => {
    dispatch(showLoadingBar());
    try {
      await inviteGuestsByEventId(eventDetails.id, formData);
      fetchGuestsList();
      dispatch(hideLoadingBar());
      toast.success("Invites have been sent successfully.");
    } catch (error) {
      dispatch(hideLoadingBar());
      toast.error(error.response.data.message);
    }
  };

  const handleBulkInvite = () => {
    const formData = {
      idList: [],
    };

    selectedGuestList.forEach((listItem) => {
      formData.idList.push(listItem.id);
    });
    sendInvites(formData);
  };

  const handleSingleInvite = (rowData) => {
    const formData = {
      idList: [rowData.id],
    };
    sendInvites(formData);
  };

  const unInviteGuests = async (formData) => {
    dispatch(showLoadingBar());
    try {
      await unInviteGuestsByEventId(eventDetails.id, formData);
      fetchGuestsList();
      dispatch(hideLoadingBar());
      toast.success("Guests have been uninvited successfully.");
    } catch (error) {
      dispatch(hideLoadingBar());
      toast.error(error.response.data.message);
    }
  };

  const handleBulkUnInvite = () => {
    const formData = {
      idList: [],
    };

    selectedGuestList.forEach((listItem) => {
      formData.idList.push(listItem.id);
    });
    unInviteGuests(formData);
  };

  const handleSingleUnInvite = (rowData) => {
    const formData = {
      idList: [rowData.id],
    };
    unInviteGuests(formData);
  };

  const handleFiltersChange = (event) => {
    setFiltersData({
      ...filtersData,
      [event.target.name]: event.target.value,
    });
  };

  const handleOpenMenu = (event, rowData) => {
    setMoreActionsElement(event.currentTarget);
    setSelectedRow(rowData);
  };

  const handleCloseMenu = () => {
    setMoreActionsElement(null);
    setSelectedRow(null);
  };

  const updateBulkInviteStatus = (status) => {
    dispatch(updateSelectedGuestList(selectedGuestList));
    dispatch(updateSelectedStatus(status));
    dispatch(updateSelectedEvent(eventDetails));
    dispatch(openSidePanel(MORE_ACTION_TYPE.EVENT_STATUS_UPDATE, selectedRow));
    setMoreActionsElement(null);
  };

  const updateInviteStatus = async (status) => {
    dispatch(updateSelectedGuestList([selectedRow]));
    dispatch(updateSelectedStatus(status));
    dispatch(updateSelectedEvent(eventDetails));
    dispatch(openSidePanel(MORE_ACTION_TYPE.EVENT_STATUS_UPDATE, selectedRow));
    setMoreActionsElement(null);
  };


  return (
    <>
      <Menu
        id="moreActionsMenu"
        anchorEl={moreActionsElement}
        open={isMoreActionsMenuOpen}
        onClose={handleCloseMenu}
      >
        {selectedRow?.sharingStatusType?.typeEnum !==
        RSVP_EVENT_STATUS.ACCEPTED ? (
          <MenuItem
            onClick={() => {
              updateInviteStatus(RSVP_EVENT_STATUS.ACCEPTED);
            }}
          >
            Accept Invite
          </MenuItem>
        ) : null}

        {selectedRow?.sharingStatusType?.typeEnum !==
        RSVP_EVENT_STATUS.DECLINED ? (
          <MenuItem
            onClick={() => {
              updateInviteStatus(RSVP_EVENT_STATUS.DECLINED);
            }}
          >
            Decline Invite
          </MenuItem>
        ) : null}
      </Menu>
      <div className="dashboard-container">
        <div className="dashboard-header-container">
          <span className="event-invite-table-heading">
            Guest List for {eventDetails?.name}
          </span>
          <div className="dashboard-filters-container">
            <div>
              <FormControl fullWidth size="small">
                <Select
                  id="rsvpStatusTypeEnum"
                  name="rsvpStatusTypeEnum"
                  value={filtersData.rsvpStatusTypeEnum}
                  placeholder="RSVP Status"
                  displayEmpty
                  className="custom-select"
                  onChange={handleFiltersChange}
                >
                  <MenuItem value="" className="default-option">
                    RSVP Status
                  </MenuItem>
                  {RSVP_EVENT_STATUS_LIST?.map((listItem) => {
                    return (
                      <MenuItem
                        key={`Group_${listItem.typeEnum}`}
                        value={listItem.typeEnum}
                      >
                        {listItem.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
            <div>
              <Button
                variant="contained"
                className="button"
                disabled={!selectedGuestList.length}
                onClick={() =>
                  updateBulkInviteStatus(RSVP_EVENT_STATUS.ACCEPTED)
                }
              >
                Accept Invite
              </Button>

              <Button
                variant="contained"
                className="secondary-button"
                disabled={!selectedGuestList.length}
                onClick={() =>
                  updateBulkInviteStatus(RSVP_EVENT_STATUS.DECLINED)
                }
              >
                Decline Invite
              </Button>
            </div>
            <div>
              <Button
                variant="contained"
                className="button"
                disabled={!selectedGuestList.length}
                onClick={handleBulkInvite}
              >
                Invite
              </Button>

              <Button
                variant="contained"
                className="secondary-button"
                disabled={!selectedGuestList.length}
                onClick={handleBulkUnInvite}
              >
                Un Invite
              </Button>
            </div>
          </div>
        </div>
        <table className="dashboard-table-container">
          <tr className="table-header">
            <th align="center">
              <input
                type="checkbox"
                checked={isAllSelected()}
                onChange={handleMultiSelect}
              />
            </th>
            <th align={"left"}>Name</th>
            <th align={"left"}>Phone</th>
            <th align={"left"}>Group Name</th>
            <th align={"center"}>Priority</th>
            <th align={"left"}>Status</th>
            <th align={"left"}>RSVP Status</th>
            <th></th>
            <th></th>
          </tr>

          <tbody>
            {filtersData.tableData?.length ? (
              <>
                {filtersData.tableData.map((dataItem) => {
                  return (
                    <tr className="table-data-row" key={dataItem.id}>
                      <td width="5%" align="center">
                        <input
                          type="checkbox"
                          checked={isRowDataSelected(dataItem)}
                          onChange={() => handleRowSelect(dataItem)}
                        />
                      </td>

                      <td width={"25%"} align={"left"}>
                        {dataFormatter(
                          FIELD_TYPE_ENUMS.STRING,
                          "name",
                          dataItem
                        )}
                      </td>

                      <td width={"15%"} align={"left"}>
                        {dataFormatter(
                          FIELD_TYPE_ENUMS.STRING,
                          "phone",
                          dataItem
                        )}
                      </td>

                      <td align={"left"}>
                        {dataFormatter(
                          FIELD_TYPE_ENUMS.STRING,
                          "groupName",
                          dataItem
                        )}
                      </td>

                      <td align={"center"}>
                        {dataFormatter(
                          FIELD_TYPE_ENUMS.NUMBER,
                          "priority",
                          dataItem
                        )}
                      </td>

                      <td width={"10%"} align={"left"}>
                        {getStatus(dataItem)}
                      </td>

                      <td width={"10%"} align={"left"}>
                        {dataItem.sharingStatusType ? (
                          <span className="invited-status-label">
                            {dataItem?.sharingStatusType?.name}&nbsp;
                            <Tooltip title={dataItem.note} arrow>
                              <InfoOutlinedIcon className="invite-note-icon" />
                            </Tooltip>
                          </span>
                        ) : null}
                      </td>

                      <td width="10%">{getInviteButton(dataItem)}</td>

                      <td width="5%">
                        <IconButton
                          onClick={(event) => handleOpenMenu(event, dataItem)}
                          id="moreActionsButton"
                        >
                          <MoreHorizIcon />
                        </IconButton>
                      </td>
                    </tr>
                  );
                })}
              </>
            ) : (
              <tr className="table-data-row">
                <td colSpan="7">No Data Available</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default EventInvite;
