import { useState } from "react";
import { RSVP_EVENT_STATUS } from "../../../utils/constants";
import "../components/guestStyles.css";
import Sangeeth from "../../../assets/Sangeet.svg";
import ThankYou from "../../../assets/ThankYou.svg";
import MissYou from "../../../assets/MissYou.png";
import { Button, FormControl, TextField } from "@mui/material";
import dayjs from "dayjs";
import { updatedEventStatus } from "../../../services/guestService";
import { toast } from "react-toastify";
import { getEventById } from "../../../services/adminService";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { hideLoadingBar, showLoadingBar } from "../../../actions/actions";
import { getDefaultTimeFormatter } from "../../../utils/commonMethods";

const RsvpEventDetails = ({ eventDetails }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [eventData, setEventData] = useState(eventDetails.webSubEvent);
  const [rsvpStatus, setRsvpStatus] = useState(
    eventDetails?.sharingStatusType?.typeEnum
  );

  const [noteForm, setNoteForm] = useState({
    note: "",
  });

  const handleFormDataChange = (event) => {
    setNoteForm({
      ...noteForm,
      [event.target.name]: event.target.value,
    });
  };

  const fetchEventDetails = async () => {
    dispatch(showLoadingBar());
    try {
      const response = await getEventById(eventData.id);
      setEventData(response?.data);
      dispatch(hideLoadingBar());
    } catch (error) {
      dispatch(hideLoadingBar());
      toast.error(error.response.data.message);
    }
  };

  const updateInviteStatus = async (status) => {
    const formData = {
      note: noteForm.note,
    };
    dispatch(showLoadingBar());
    try {
      await updatedEventStatus(eventData.id, status, formData);
      fetchEventDetails();
      dispatch(hideLoadingBar());
      setRsvpStatus(status);
      setNoteForm({
        ...noteForm,
        note: "",
      });
    } catch (error) {
      dispatch(hideLoadingBar());
      toast.error(error.response.data.message);
    }
  };

  const formatDate = (date) => {
    return dayjs(date).format("DD MMM, YYYY");
  };

  const handleEventNavigate = () => {
    navigate(`/guest/event/${eventData.id}`);
  };

  const getEvent = () => {
    switch (rsvpStatus) {
      case RSVP_EVENT_STATUS.PENDING:
        return (
          <>
            <div className="rsvp-event-icon-container">
              <img src={Sangeeth} alt="Event" className="rsvp-event-icon" />
            </div>
            <div className="rsvp-event-heading-container">
              <span className="rsvp-event-name">{eventData.name}</span>
              <span className="rsvp-event-date">
                {formatDate(eventData.startDate)}
              </span>
            </div>

            <div
              className="rsvp-event-details-button"
              onClick={handleEventNavigate}
            >
              Event Details
            </div>

            <div className="rsvp-event-response-container">
              <span className="rsvp-event-response-text">Kindly Respond</span>
              <span className="rsvp-event-respond-by-date">
                By {formatDate(eventData.rsvpEndDate)}
              </span>
            </div>

            <div className="rsvp-event-looking-forward-text">
              We look forward to celebrating with you!
            </div>

            <div className="event-note-form-container">
              <FormControl fullWidth size="small">
                <TextField
                  id="note"
                  name="note"
                  className="form-note-input"
                  size="small"
                  placeholder="Kindly offer your feedback or Share your reasons for declining here..."
                  value={noteForm.note}
                  onChange={handleFormDataChange}
                  multiline
                  rows={2}
                />
              </FormControl>
            </div>

            <Button
              className="rsvp-button"
              onClick={() => updateInviteStatus(RSVP_EVENT_STATUS.ACCEPTED)}
            >
              Accept
            </Button>

            <span
              className="rsvp-decline"
              onClick={() => updateInviteStatus(RSVP_EVENT_STATUS.DECLINED)}
            >
              Decline
            </span>
          </>
        );
      case RSVP_EVENT_STATUS.ACCEPTED:
        return (
          <>
            <div className="rsvp-event-icon-container">
              <img src={ThankYou} alt="Event" className="rsvp-event-icon" />
            </div>

            <div className="rsvp-event-acceptance-container">
              <span>For</span>
              <span>Accepting Invite</span>
            </div>

            <div className="rsvp-event-heading-container">
              <span className="rsvp-event-name">{eventData.eventName}</span>
              <span className="rsvp-event-date">
                {formatDate(eventData.startDate)}
              </span>
            </div>

            <div
              className="rsvp-event-details-button"
              onClick={handleEventNavigate}
            >
              Event Details
            </div>
          </>
        );

      case RSVP_EVENT_STATUS.DECLINED:
        return (
          <>
            <img src={MissYou} alt="Event" className="miss-you-image" />

            <div className="thank-you-container">
              <span>Thank you for</span>
              <span>Responding</span>
            </div>
          </>
        );
      default:
        return <></>;
    }
  };

  const getActionButtons = () => {
    switch (rsvpStatus) {
      case RSVP_EVENT_STATUS.ACCEPTED:
        return (
          <>
            <span className="event-card-action-button-highlighted">
              Accepted
            </span>
            <div
              className="event-card-action-button-link-container"
              onClick={() => updateInviteStatus(RSVP_EVENT_STATUS.DECLINED)}
            >
              <span className="event-card-action-button-change-text">
                Change To
              </span>
              <span className="event-card-action-button-link">Decline</span>
            </div>
          </>
        );
      case RSVP_EVENT_STATUS.DECLINED:
        return (
          <>
            <div
              className="event-card-action-button-link-container"
              onClick={() => updateInviteStatus(RSVP_EVENT_STATUS.ACCEPTED)}
            >
              <span className="event-card-action-button-change-text">
                Change To
              </span>
              <span className="event-card-action-button-link">Accept</span>
            </div>
            <span className="event-card-action-button-highlighted">
              Declined
            </span>
          </>
        );
      default:
        return (
          <>
            <span
              className="event-card-action-button"
              onClick={() => updateInviteStatus(RSVP_EVENT_STATUS.ACCEPTED)}
            >
              Accept
            </span>
            <span
              className="event-card-action-button"
              onClick={() => updateInviteStatus(RSVP_EVENT_STATUS.DECLINED)}
            >
              Decline
            </span>
          </>
        );
    }
  };

  return (
    <div className="rsvp-event-details-container">
      <div className="rsvp-event-icon-container">
        <img src={Sangeeth} alt="Event" className="rsvp-event-icon" />
      </div>
      <div className="rsvp-event-heading-container">
        <span className="rsvp-event-name">{eventData.name}</span>
        <span className="rsvp-event-date">
          {formatDate(eventData.startDate)}
        </span>
      </div>

      <div className="rsvp-event-time-details-container">
        <span className="rsvp-event-time-details">
          {getDefaultTimeFormatter(eventData?.startDate)}
        </span>
        <span>-</span>
        <span className="rsvp-event-time-details">
          {getDefaultTimeFormatter(eventData?.endDate)}
        </span>
      </div>

      <div className="rsvp-event-details-button" onClick={handleEventNavigate}>
        Event Details
      </div>

      <div className="rsvp-event-response-container">
        <span className="rsvp-event-response-text">Kindly Respond</span>
        <span className="rsvp-event-respond-by-date">
          By {formatDate(eventData.rsvpEndDate)}
        </span>
      </div>

      <div className="rsvp-event-looking-forward-text">
        We look forward to celebrating with you!
      </div>

      <div className="event-note-form-container w-25">
        <FormControl fullWidth size="small">
          <TextField
            id="note"
            name="note"
            className="form-note-input"
            size="small"
            placeholder="Kindly offer your feedback or Share your reasons for declining here..."
            value={noteForm.note}
            onChange={handleFormDataChange}
            multiline
            rows={2}
          />
        </FormControl>
      </div>

      <div className="event-card-actions">{getActionButtons()}</div>
      {/* <Button
        className="rsvp-button"
        onClick={() => updateInviteStatus(RSVP_EVENT_STATUS.ACCEPTED)}
      >
        Accept
      </Button>

      <span
        className="rsvp-decline"
        onClick={() => updateInviteStatus(RSVP_EVENT_STATUS.DECLINED)}
      >
        Decline
      </span> */}
    </div>
  );
};

export default RsvpEventDetails;
