import { useFormik } from "formik";
import * as Yup from "yup";
import { FORM_VALIDATION_MESSAGES } from "../../../utils/constants";
import {
  Button,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import {
  getFormControlText,
  getFormControlValue,
  isFormControlError,
} from "../../../services/formHelperService";
import { useDispatch, useSelector } from "react-redux";
import { saveEvent } from "../../../services/adminService";
import { toast } from "react-toastify";
import {
  hideLoadingBar,
  refreshData,
  showLoadingBar,
} from "../../../actions/actions";
import "../index.css";
import CustomDateTimePicker from "../../common/CustomDateTimePicker";

const AddEventForm = ({ closePanel, eventDetails }) => {
  const dispatch = useDispatch();

  const masterData = useSelector((state) => state.masterDataReducer);
  const eventTypes = masterData.eventTypes;

  const initialValues = {
    id: getFormControlValue(eventDetails?.id),
    subEventTypeEnum: getFormControlValue(eventDetails?.subEventType.typeEnum),
    name: getFormControlValue(eventDetails?.name),
    startDate: getFormControlValue(eventDetails?.startDate),
    endDate: getFormControlValue(eventDetails?.endDate),
    location: getFormControlValue(eventDetails?.location),
    guestLimit: getFormControlValue(eventDetails?.guestLimit),
    rsvpEndDate: getFormControlValue(eventDetails?.rsvpEndDate),
    attireDescription: getFormControlValue(eventDetails?.attireDescription),
    attireLink: getFormControlValue(eventDetails?.attireLink),
  };

  const handleFormSubmit = (values) => {
    saveEventData(values);
  };

  const eventForm = useFormik({
    initialValues,
    validationSchema: Yup.object({
      name: Yup.string().required(FORM_VALIDATION_MESSAGES.EVENT_NAME_REQUIRED),
      subEventTypeEnum: Yup.string().required(
        FORM_VALIDATION_MESSAGES.EVENT_TYPE_REQUIRED
      ),
      startDate: Yup.date().required(
        FORM_VALIDATION_MESSAGES.EVENT_START_TIME_REQUIRED
      ),
      endDate: Yup.date().min(
        Yup.ref("startDate"),
        "End date must be after start date"
      ),
      location: Yup.string().required(
        FORM_VALIDATION_MESSAGES.EVENT_LOCATION_REQUIRED
      ),
      guestLimit: Yup.string().required(
        FORM_VALIDATION_MESSAGES.EVENT_GUEST_LIMIT_REQUIRED
      ),
      rsvpEndDate: Yup.string().required(
        FORM_VALIDATION_MESSAGES.RESPONSE_END_TIME_REQUIRED
      ),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      handleFormSubmit(values);
    },
  });

  const saveEventData = async (eventData) => {
    dispatch(showLoadingBar());
    try {
      await saveEvent(eventData);
      dispatch(refreshData());
      closePanel();
      dispatch(hideLoadingBar());
      toast.success("Event has been saved successfully.");
    } catch (error) {
      dispatch(hideLoadingBar());
      toast.error(error.response.data.message);
    }
  };

  return (
    <div className="side-panel-root">
      <form className="form-container">
        <FormControl fullWidth size="small">
          <span className="form-control-label">Event Type *</span>
          <Select
            id="subEventTypeEnum"
            name="subEventTypeEnum"
            value={eventForm.values.subEventTypeEnum}
            placeholder="Event Type"
            displayEmpty
            className="form-select-control"
            onChange={eventForm.handleChange}
            onBlur={eventForm.handleBlur}
            fullWidth
          >
            <MenuItem value="" disabled className="default-option">
              Event Type
            </MenuItem>
            {eventTypes?.map((eventListItem) => {
              return (
                <MenuItem
                  key={`eventType_${eventListItem.typeEnum}`}
                  value={eventListItem.typeEnum}
                >
                  {eventListItem.name}
                </MenuItem>
              );
            })}
          </Select>
          {isFormControlError(eventForm, "subEventTypeEnum") && (
            <FormHelperText className="form-control-error">
              {getFormControlText(eventForm, "subEventTypeEnum")}
            </FormHelperText>
          )}
        </FormControl>

        <FormControl fullWidth size="small">
          <span className="form-control-label">Event Name *</span>
          <TextField
            id="name"
            name="name"
            className="form-input-control"
            size="small"
            placeholder="Event Name"
            value={eventForm.values.name}
            onChange={eventForm.handleChange}
            onBlur={eventForm.handleBlur}
          />
          {isFormControlError(eventForm, "name") && (
            <FormHelperText className="form-control-error">
              {getFormControlText(eventForm, "name")}
            </FormHelperText>
          )}
        </FormControl>

        <FormControl fullWidth size="small">
          <span className="form-control-label">Start Date & Time *</span>
          <CustomDateTimePicker
            id="startDate"
            name="startDate"
            value={eventForm.values.startDate}
            onChange={eventForm.handleChange}
            onBlur={eventForm.handleBlur}
          />

          {isFormControlError(eventForm, "startDate") && (
            <FormHelperText className="form-control-error">
              {getFormControlText(eventForm, "startDate")}
            </FormHelperText>
          )}
        </FormControl>

        <FormControl fullWidth size="small">
          <span className="form-control-label">End Date & Time</span>
          <CustomDateTimePicker
            id="endDate"
            name="endDate"
            value={eventForm.values.endDate}
            onChange={eventForm.handleChange}
            onBlur={eventForm.handleBlur}
          />

          {isFormControlError(eventForm, "endDate") && (
            <FormHelperText className="form-control-error">
              {getFormControlText(eventForm, "endDate")}
            </FormHelperText>
          )}
        </FormControl>

        <FormControl fullWidth size="small">
          <span className="form-control-label">Event Location *</span>
          <TextField
            id="location"
            name="location"
            className="form-input-control"
            size="small"
            placeholder="Address"
            value={eventForm.values.location}
            onChange={eventForm.handleChange}
            onBlur={eventForm.handleBlur}
          />
          {isFormControlError(eventForm, "location") && (
            <FormHelperText className="form-control-error">
              {getFormControlText(eventForm, "location")}
            </FormHelperText>
          )}
        </FormControl>

        <FormControl fullWidth size="small">
          <span className="form-control-label">Guest Limit *</span>
          <TextField
            id="guestLimit"
            name="guestLimit"
            className="form-input-control"
            size="small"
            placeholder="Guest Limit"
            value={eventForm.values.guestLimit}
            onChange={eventForm.handleChange}
            onBlur={eventForm.handleBlur}
          />
          {isFormControlError(eventForm, "guestLimit") && (
            <FormHelperText className="form-control-error">
              {getFormControlText(eventForm, "guestLimit")}
            </FormHelperText>
          )}
        </FormControl>

        <FormControl fullWidth size="small">
          <span className="form-control-label">Respond By Date & Time *</span>
          <CustomDateTimePicker
            id="rsvpEndDate"
            name="rsvpEndDate"
            value={eventForm.values.rsvpEndDate}
            onChange={eventForm.handleChange}
            onBlur={eventForm.handleBlur}
          />

          {isFormControlError(eventForm, "rsvpEndDate") && (
            <FormHelperText className="form-control-error">
              {getFormControlText(eventForm, "rsvpEndDate")}
            </FormHelperText>
          )}
        </FormControl>

        <FormControl fullWidth size="small">
          <span className="form-control-label">Dress Code</span>
          <TextField
            id="attireDescription"
            name="attireDescription"
            className="form-input-control"
            size="small"
            placeholder="Dress Code"
            value={eventForm.values.attireDescription}
            onChange={eventForm.handleChange}
            onBlur={eventForm.handleBlur}
          />
          {isFormControlError(eventForm, "attireDescription") && (
            <FormHelperText className="form-control-error">
              {getFormControlText(eventForm, "attireDescription")}
            </FormHelperText>
          )}
        </FormControl>

        <FormControl fullWidth size="small">
          <span className="form-control-label">Dress Code Samples</span>
          <TextField
            id="attireLink"
            name="attireLink"
            className="form-input-control"
            size="small"
            placeholder="Example Links for Dress Code"
            value={eventForm.values.attireLink}
            onChange={eventForm.handleChange}
            onBlur={eventForm.handleBlur}
          />
          {isFormControlError(eventForm, "attireLink") && (
            <FormHelperText className="form-control-error">
              {getFormControlText(eventForm, "attireLink")}
            </FormHelperText>
          )}
        </FormControl>
      </form>

      <div className="form-actions-container">
        <Button
          variant="contained"
          className="cancel-button"
          onClick={closePanel}
        >
          Cancel
        </Button>

        <Button
          variant="contained"
          className="submit-button"
          onClick={eventForm.submitForm}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default AddEventForm;
