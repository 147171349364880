import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import SendIcon from "@mui/icons-material/Send";
import {
  FormControl,
  FormHelperText,
  IconButton,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as Yup from "yup";
import {
  hideLoadingBar,
  showLoadingBar,
  updateSelectedContact,
} from "../../../actions/actions";
import {
  getFormControlText,
  isFormControlError,
} from "../../../services/formHelperService";
import {
  getMessagesById,
  sendMessage,
} from "../../../services/individualService";
import { CONVERSATION_TYPE_ENUM, USER_ROLES } from "../../../utils/constants";
import MessageTile from "../MessageTile/MessageTile";
import NoMessages from "../NoContentTemplates/NoMessages";
import SelectConversation from "../NoContentTemplates/SelectConversation";
import "./MessageWindow.css";

const MessageWindow = () => {
  const dispatch = useDispatch();
  const messagingReducer = useSelector((state) => state.messagingReducer);
  const [selectedContact, setSelectedContact] = useState(null);
  const [messagesList, setMessagesList] = useState([]);

  const messageBodyRef = useRef(null);
  const isSmallScreen = useMediaQuery("(max-width:1024px)");

  const [initialScroll, setInitialScroll] = useState(true);
  const [autoScroll, setAutoScroll] = useState(true); // Track auto-scroll behavior

  const userInfoReducer = useSelector((state) => state.userInfoReducer);
  const userInfo = userInfoReducer?.userInfo;
  const isUserAdmin = userInfo?.roleType?.typeEnum === USER_ROLES.admin;

  useEffect(() => {
    const contact = messagingReducer.selectedContact;
    setSelectedContact(contact);

    if (contact) {
      fetchMessagesByConversationId(contact.id);

      const intervalId = setInterval(() => {
        fetchMessagesByConversationId(contact.id);
      }, 30000); // 30 seconds interval

      return () => clearInterval(intervalId);
    }
  }, [messagingReducer.selectedContact]);

  const fetchMessagesByConversationId = async (conversationId) => {
    const messageBody = messageBodyRef.current;
    const previousScrollTop = messageBody?.scrollTop;

    const isUserAtBottom =
      messageBody &&
      messageBody?.scrollHeight - messageBody?.scrollTop ===
        messageBody?.clientHeight;

    dispatch(showLoadingBar());
    try {
      const response = await getMessagesById(conversationId);
      setMessagesList(response?.data ?? []);
      dispatch(hideLoadingBar());

      if (initialScroll) {
        messageBody.scrollTop = messageBody?.scrollHeight; // Scroll to bottom on the first load
        setInitialScroll(false);
      } else if (isUserAtBottom) {
        messageBody.scrollTop = messageBody?.scrollHeight; // Auto-scroll to bottom only if user is already there
      } else {
        // Maintain the scroll position if the user isn't at the bottom
        setTimeout(() => {
          if (messageBody) {
            messageBody.scrollTop = previousScrollTop;
          }
        }, 0);
      }
    } catch (error) {
      dispatch(hideLoadingBar());
      toast.error(error?.response?.data?.message);
    }
  };

  const handleScroll = () => {
    const messageBody = messageBodyRef.current;
    if (messageBody) {
      const { scrollTop, scrollHeight, clientHeight } = messageBody;
      const isAtBottom = scrollHeight - scrollTop === clientHeight;
      setAutoScroll(isAtBottom); // Enable auto-scroll if the user is at the bottom
    }
  };

  useEffect(() => {
    const messageBody = messageBodyRef.current;
    messageBody?.addEventListener("scroll", handleScroll);
    return () => messageBody?.removeEventListener("scroll", handleScroll);
  }, []);

  const showMessageInput = () => {
    return (
      isUserAdmin ||
      (!isUserAdmin &&
        selectedContact.conversationTypeEnum !==
          CONVERSATION_TYPE_ENUM.ANNOUNCEMENTS)
    );
  };

  const newMessageForm = useFormik({
    initialValues: {
      message: "",
    },
    validationSchema: Yup.object({
      message: Yup.string().required("Please enter a message"),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      handleFormSubmit(values);
    },
  });

  const handleFormSubmit = (values) => {
    const messageRequestBody = {
      conversationId: selectedContact.id,
      content: values.message.trim(),
    };
    sendConversationMessage(messageRequestBody);
  };

  const sendConversationMessage = async (requestBody) => {
    dispatch(showLoadingBar());
    try {
      await sendMessage(requestBody);
      dispatch(hideLoadingBar());
      newMessageForm.resetForm();
      fetchMessagesByConversationId(selectedContact.id);
    } catch (error) {
      dispatch(hideLoadingBar());
      toast.error(error.response.data.message);
    }
  };

  const showBackButton = () => {
    return isSmallScreen && selectedContact;
  };

  const handleBack = () => {
    setSelectedContact(null);
    dispatch(updateSelectedContact(null));
  };

  return (
    <>
      {selectedContact ? (
        <>
          <div className="chat-header-container">
            <div className="chat-header contact-info-container">
              {showBackButton() ? (
                <IconButton onClick={handleBack}>
                  <ArrowBackRoundedIcon />
                </IconButton>
              ) : null}
              <span className="chat-header-name">{selectedContact.name}</span>
            </div>
          </div>
          <div className={`message-body-container`} ref={messageBodyRef}>
            <div className="message-body">
              {messagesList.length ? (
                <>
                  {messagesList.map((messageData) => {
                    return (
                      <div key={messageData.id}>
                        <MessageTile messageData={messageData} />
                      </div>
                    );
                  })}
                </>
              ) : (
                <NoMessages />
              )}
            </div>
          </div>
          {showMessageInput() ? (
            <div className="message-footer-container">
              <div className="message-input-footer">
                <div className="message-input-container">
                  <FormControl fullWidth>
                    <TextField
                      id="message"
                      name="message"
                      size="small"
                      placeholder="Enter Message ..."
                      multiline
                      maxRows={2}
                      onChange={newMessageForm.handleChange}
                      onBlur={newMessageForm.handleBlur}
                      value={newMessageForm.values.message}
                      inputProps={{ maxLength: 400 }}
                    />
                    {/* {isFormControlError(newMessageForm, "message") && (
                      <FormHelperText className="form-control-error">
                        {getFormControlText(newMessageForm, "message")}
                      </FormHelperText>
                    )} */}
                  </FormControl>
                  <div className="character-count-container">
                    <span className="character-count">
                      {newMessageForm.values.message.length}/400
                    </span>
                  </div>
                </div>
                <IconButton
                  id="send-button"
                  onClick={newMessageForm.submitForm}
                  disabled={!newMessageForm.values.message.trim()?.length}
                >
                  <SendIcon />
                </IconButton>
              </div>
            </div>
          ) : (
            <div className="admin-message-footer">
              <span className="admin-warning-text">
                You will not be able to respond to Admin Messages
              </span>
            </div>
          )}
        </>
      ) : (
        <SelectConversation />
      )}
    </>
  );
};

export default MessageWindow;
