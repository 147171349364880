import {
  Box,
  Button,
  Chip,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import "./index.css";
import {
  getFormControlText,
  isFormControlError,
} from "../../../services/formHelperService";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { hideLoadingBar, showLoadingBar } from "../../../actions/actions";
import { getGroupList } from "../../../services/adminService";
import { toast } from "react-toastify";
import { sendMessage } from "../../../services/individualService";

const NewBroadCastMessage = ({ closeModal }) => {
  const dispatch = useDispatch();
  const [groupList, setGroupList] = useState([]);

  const initialValues = {
    to: "",
    groupId: [],
    message: "",
  };

  const fetchGroupList = async () => {
    dispatch(showLoadingBar());
    setGroupList([]);
    try {
      const response = await getGroupList();
      setGroupList(response?.data ?? []);
      dispatch(hideLoadingBar());
    } catch (error) {
      dispatch(hideLoadingBar());
      toast.error(error.response.data.message);
    }
  };

  useEffect(() => {
    fetchGroupList();
  }, []);

  const newBroadcastMessageForm = useFormik({
    initialValues,
    validationSchema: Yup.object({
      to: Yup.string().required("Please select an option"),
      groupId: Yup.array().when("to", {
        is: "GROUP",
        then: () =>
          Yup.array()
            .min(1, "Please select atleast one group")
            .required("Please select a group"),
        otherwise: () => Yup.array().notRequired(),
      }),
      message: Yup.string().required("Message cannot be empty"),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      handleFormSubmit(values);
    },
  });

  const handleGroupSelect = (event) => {
    const {
      target: { value },
    } = event;

    const formattedValue = typeof value === "string" ? value.split(",") : value;
    newBroadcastMessageForm.setFieldValue("groupId", formattedValue, true);
  };

  const handleFormSubmit = (values) => {
    const messageRequestBody = {
      groupIds: values.groupId,
      content: values.message.trim(),
      isBroadcastAllIndividuals: values.to === "ALL",
    };
    sendConversationMessage(messageRequestBody);
  };

  const sendConversationMessage = async (requestBody) => {
    dispatch(showLoadingBar());
    try {
      await sendMessage(requestBody);
      dispatch(hideLoadingBar());
      closeModal();
    } catch (error) {
      dispatch(hideLoadingBar());
      toast.error(error.response?.data?.message);
    }
  };

  const isBrodcastToGroup = () => {
    const messageTo = newBroadcastMessageForm.values.to;
    return messageTo === "GROUP";
  };

  return (
    <div className="new-broadcast-message-root">
      <div className="new-message-header-container">
        <span className="new-message-header">New Message</span>
      </div>
      <div className="message-type-input-container">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} lg={4}>
            <FormControl fullWidth size="small">
              <span className="form-control-label">Message To</span>
              <Select
                id="to"
                name="to"
                placeholder="Select Event"
                value={newBroadcastMessageForm.values.to}
                displayEmpty
                className="form-select-control"
                onChange={newBroadcastMessageForm.handleChange}
                onBlur={newBroadcastMessageForm.handleBlur}
                fullWidth
              >
                <MenuItem value="" disabled className="default-option">
                  To
                </MenuItem>

                <MenuItem value={"ALL"}>All</MenuItem>

                <MenuItem value={"GROUP"}>Group</MenuItem>
              </Select>
              {isFormControlError(newBroadcastMessageForm, "to") && (
                <FormHelperText className="form-control-error">
                  {getFormControlText(newBroadcastMessageForm, "to")}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          {isBrodcastToGroup() ? (
            <Grid item xs={12} sm={6} lg={8}>
              <FormControl fullWidth size="small">
                <span className="form-control-label">Group</span>
                <Select
                  id="groupId"
                  name="groupId"
                  placeholder="Select Groups"
                  value={newBroadcastMessageForm.values.groupId}
                  displayEmpty
                  multiple
                  className="form-select-control"
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected?.map((value) => {
                        const group = groupList.find(
                          (item) => item.id === value
                        );
                        return (
                          <Chip
                            key={value}
                            label={group ? group.name : value}
                          />
                        );
                      })}
                    </Box>
                  )}
                  onChange={handleGroupSelect}
                  onBlur={newBroadcastMessageForm.handleBlur}
                  fullWidth
                >
                  <MenuItem value={""} disabled className="default-option">
                    Select a Group
                  </MenuItem>
                  {groupList?.map((listItem) => {
                    return (
                      <MenuItem
                        key={`Group_${listItem.id}`}
                        value={listItem.id}
                      >
                        {listItem.name}
                      </MenuItem>
                    );
                  })}
                </Select>
                {isFormControlError(newBroadcastMessageForm, "groupId") && (
                  <FormHelperText className="form-control-error">
                    {getFormControlText(newBroadcastMessageForm, "groupId")}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          ) : null}
        </Grid>
      </div>

      <div className="new-message-input-container">
        <FormControl fullWidth>
          <TextField
            id="message"
            name="message"
            size="small"
            placeholder="Enter Message ..."
            multiline
            rows={4}
            onChange={newBroadcastMessageForm.handleChange}
            onBlur={newBroadcastMessageForm.handleBlur}
            value={newBroadcastMessageForm.values.message}
            inputProps={{ maxLength: 400 }}
          />
          {isFormControlError(newBroadcastMessageForm, "message") && (
            <FormHelperText className="form-control-error">
              {getFormControlText(newBroadcastMessageForm, "message")}
            </FormHelperText>
          )}
        </FormControl>
        <div className="character-count-container">
          <span className="character-count">
            {newBroadcastMessageForm.values.message.length}/400
          </span>
        </div>
      </div>
      <div className="new-message-actions-container">
        <Button
          variant="contained"
          className="cancel-button"
          onClick={closeModal}
        >
          Cancel
        </Button>

        <Button
          variant="contained"
          className="submit-button"
          onClick={() => {
            newBroadcastMessageForm.submitForm();
          }}
          disabled={!newBroadcastMessageForm.isValid}
        >
          Send
        </Button>
      </div>
    </div>
  );
};

export default NewBroadCastMessage;
